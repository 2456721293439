import { Button, Flex } from '@aws-amplify/ui-react';
import { DrawingManager, GoogleMap } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { ContactsSearchParams } from '../utils/ContactsSearchParams';
import { TbCircle, TbHandStop, TbLassoPolygon, TbRectangle } from "react-icons/tb";
import General from '../utils/GeneralUtils';
import { InstitutionsSearchParams } from '../utils/InstitutionsSearchParams';

const MAX_SHAPES = 10

export function ShapeOnMap({ locationsResponse, searchParams, onChange }: {
  locationsResponse?: any,
  searchParams?: ContactsSearchParams | InstitutionsSearchParams | InstitutionsSearchParams | any | undefined,
  onChange: (params: string) => void
}) {

  console.log('Re-rendering ShapeOnMap component')

  const [activeTool, setActiveTool] = useState<google.maps.drawing.OverlayType | null>(null);
  const [numberOfShapes, setNumberOfShapes] = useState<number>(0);
  const mapRef = useRef<google.maps.Map>();
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager>();
  const shapesRef = useRef<any[]>([]);
  const zoom = useRef<number | undefined>(4)
  const mapCenter = useRef<google.maps.LatLng | undefined>(new google.maps.LatLng({ lat: 39.8283, lng: -98.5795 }))

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  }

  const shapeOptions = {
    strokeColor: '#4d00e6',
    strokeWeight: 1,
    fillOpacity: 0.2,
    fillColor: '#4d00e6'
  }

  function updateShapesRefCount() {
    setNumberOfShapes(shapesRef.current.length)
  }

  function drawPolygon(paths: google.maps.LatLngLiteral[]) {
    const polygon = new google.maps.Polygon({
      ...shapeOptions,
      paths,
      map: mapRef.current,
    });
    polygon.set('shapeType', 'polygon')
    shapesRef.current.push(polygon);
    updateShapesRefCount()
  }

  function drawCircle(center: google.maps.LatLngLiteral, radius: number) {
    const circle = new google.maps.Circle({
      ...shapeOptions,
      center,
      radius,
      map: mapRef.current,
    });
    circle.set('shapeType', 'circle')
    shapesRef.current.push(circle);
    updateShapesRefCount()
  }

  function drawRectangle(northeast: google.maps.LatLngLiteral, southwest: google.maps.LatLngLiteral) {
    const rectangle = new google.maps.Rectangle({
      ...shapeOptions,
      bounds: {
        north: northeast.lat,
        south: southwest.lat,
        east: northeast.lng,
        west: southwest.lng,
      },
      map: mapRef.current,
    });
    rectangle.set('shapeType', 'rectangle')
    shapesRef.current.push(rectangle);
    updateShapesRefCount()
  }

  const onMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    renderShapesInSearchParams()
  };

  const onAddShape = (shapeType: string, shape: any) => {
    shape.shapeType = shapeType
    shapesRef.current.push(shape);
    updateShapesRefCount()
    updateNewSearchParams()
  };

  const startDrawing = (tool: google.maps.drawing.OverlayType) => {
    if (activeTool === tool) {
      startHandControl()
      return;
    }
    if (drawingManagerRef.current) {
      setActiveTool(tool)
      drawingManagerRef.current.setDrawingMode(tool);
    }
  };

  const startHandControl = () => {
    if (drawingManagerRef.current) {
      setActiveTool(null)
      drawingManagerRef.current.setDrawingMode(null);
    }
  }

  const clearDrawings = (updateParams: boolean) => {
    for (const shape of shapesRef.current) {
      shape.setMap(null);
    }
    startHandControl()
    shapesRef.current = [];
    updateShapesRefCount()
    if (updateParams) {
      updateNewSearchParams()
    }
  };

  const updateNewSearchParams = () => {
    const serializedShapes = serializeShapes().filter(it => it)
    onChange(General.encode({ shapes: serializedShapes, zoom: zoom.current, center: mapCenter.current }))
  }

  const serializeShapes = () => {
    return shapesRef.current.map(it => {
      if (it.shapeType === 'rectangle') {
        return {
          type: it.shapeType,
          northeast: (it as google.maps.Rectangle).getBounds()?.getNorthEast().toJSON(),
          southwest: (it as google.maps.Rectangle).getBounds()?.getSouthWest().toJSON()
        }
      }
      if (it.shapeType === 'circle') {
        return {
          type: it.shapeType,
          center: (it as google.maps.Circle).getCenter()?.toJSON(),
          radius: (it as google.maps.Circle).getRadius()
        }
      }
      if (it.shapeType === 'polygon') {
        return {
          type: it.shapeType,
          paths: (it as google.maps.Polygon).getPath().getArray().map((path: any) => path.toJSON())
        }
      }
      return null
    }).filter(it => it)
  }

  function renderShapesInSearchParams() {
    if (!mapRef.current) return
    if (!searchParams?.shapes.json) return
    const { shapes, zoom, center } = searchParams?.shapes.json

    // Render shapes
    if (Array.isArray(shapes)) {
      clearDrawings(false);
      for (const shape of shapes) {
        if (!(shape && shape.type)) continue
        if (shape.type === 'rectangle') drawRectangle(shape.northeast, shape.southwest)
        if (shape.type === 'circle') drawCircle(shape.center, shape.radius)
        if (shape.type === 'polygon') drawPolygon(shape.paths)
      }
    }

    // Set zoom
    if (zoom) mapRef.current.setZoom(zoom)

    // Set center
    if (center) mapRef.current.setCenter(center)
  }

  useEffect(() => {
    if (numberOfShapes >= MAX_SHAPES) {
      startHandControl()
    }
  }, [numberOfShapes])

  return <>
    <Flex
      justifyContent='space-between'
      style={{ width: '100%', padding: 10, position: 'absolute', zIndex: 1000 }}
    >
      <Flex width='200px'>
        <Button className='tiny' onClick={startHandControl}><TbHandStop fontSize={20}/></Button>
      </Flex>
      <Flex width='30%' position='relative' gap={3}>
        <Button
          style={{ flex: 1 }}
          onClick={startDrawing.bind(null, google.maps.drawing.OverlayType.CIRCLE)}
          className={activeTool === google.maps.drawing.OverlayType.CIRCLE ? 'tiny active' : 'tiny'}
          data-tooltip-id="main-tooltip"
          data-tooltip-content='Draw a circle'
          disabled={numberOfShapes >= MAX_SHAPES}
        >
          <TbCircle fontSize={20}/>
        </Button>
        <Button
          style={{ flex: 1 }}
          onClick={startDrawing.bind(null, google.maps.drawing.OverlayType.RECTANGLE)}
          className={activeTool === google.maps.drawing.OverlayType.RECTANGLE ? 'tiny active' : 'tiny'}
          data-tooltip-id="main-tooltip"
          data-tooltip-content='Draw a rectangle'
          disabled={numberOfShapes >= MAX_SHAPES}
        >
          <TbRectangle fontSize={20}/>
        </Button>
        <Button
          style={{ flex: 1 }}
          onClick={startDrawing.bind(null, google.maps.drawing.OverlayType.POLYGON)}
          className={activeTool === google.maps.drawing.OverlayType.POLYGON ? 'tiny active' : 'tiny'}
          data-tooltip-id="main-tooltip"
          data-tooltip-content='Draw a polygon'
          disabled={numberOfShapes >= MAX_SHAPES}
        >
          <TbLassoPolygon fontSize={20}/>
        </Button>
        {/* <div
          style={{
            position: 'absolute',
            right: -230,
            top: 8,
            fontSize: 13,
            color: 'var(--amplify-colors-red-80)',
            opacity: numberOfShapes >= MAX_SHAPES ? 1 : 0
          }}
        >
          <i>At most { MAX_SHAPES } shapes allowed on map</i>
        </div> */}
      </Flex>
      <Flex width='200px' justifyContent='flex-end'>
        <Button className='tiny' onClick={clearDrawings.bind(null, true)}>Clear all shapes ({ numberOfShapes })</Button>
      </Flex>
    </Flex>

    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter.current}
      zoom={zoom.current}
      options={mapOptions}
      onLoad={onMapLoad}
      onZoomChanged={() => {
        if (mapRef.current) {
          zoom.current = mapRef.current?.getZoom()
        }
      }}
      onCenterChanged={() => {
        if (mapRef.current) {
          mapCenter.current = mapRef.current?.getCenter()
        }
      }}
    >
      <DrawingManager
        onPolygonComplete={onAddShape.bind(null, 'polygon')}
        onCircleComplete={onAddShape.bind(null, 'circle')}
        onRectangleComplete={onAddShape.bind(null, 'rectangle')}
        onLoad={(drawingManager) => {
          drawingManagerRef.current = drawingManager
        }}
        options={{
          drawingControl: false,
          polygonOptions: shapeOptions,
          rectangleOptions: shapeOptions,
          circleOptions: shapeOptions,
          drawingControlOptions: {
            drawingModes: [
              google.maps.drawing.OverlayType.CIRCLE,
              google.maps.drawing.OverlayType.RECTANGLE,
              google.maps.drawing.OverlayType.POLYGON
            ]
          },
        }}
      />
      {/* {
        (locations || []).map((institution: any) => {
          return <Marker
            key={institution.id}
            position={{ lat: institution.coordinates[1], lng: institution.coordinates[0] }}
          />
        })
      } */}
    </GoogleMap>
  </>;
}

export const MemoShapeOnMap = React.memo(ShapeOnMap)