import 'rc-slider/assets/index.css';
import { useContext, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingBars from '../custom-components/LoadingBars';
import { GlobalContext } from '../global-context';
import General from '../utils/GeneralUtils';
import InstitutionTopCard from '../custom-components/InstitutionTopCard';
import { TeamDao } from '../utils/TeamDao';
import { APIAction, Entity } from '../utils/CustomTypes';
import CustomList from '../custom-components/CustomList';
import { LocalStorageKeys, LocalStorageService } from '../utils/LocalStorageService';
import { Button } from '@aws-amplify/ui-react';

const PAGE_SIZE = 2000

export function ChildInstitution(props: any) {

  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();

  const { id } = useParams()
  const [uid] = useState(id);
  const [response, setResponse] = useState<any>(null);
  const [contactsSearchResponse, setContactsSearchResponse] = useState<any>(null);
  const [isExportingToCsv, setIsExportingToCsv] = useState(false);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [isContactsTableExpanded, setIsContactsTableExpanded] = useState(LocalStorageService.get(LocalStorageKeys.IS_CONTACTS_LIST_ON_CHILD_PAGE_EXPANDED) === 'true');

  async function loadInstitution() {
    try {
      setIsLoadingContacts(true)
      const bodyJson = await TeamDao.dbGet({ entity: globalContext.environment.entities.childInstitution.type, id: uid as string })
      setResponse(bodyJson);
      loadContacts(bodyJson.data._id)
      General.setTitle(document, bodyJson.data._source.name, globalContext);
    } catch (err) {
      General.logAndToastError('Failed to load institution', err)
    }
  }

  async function loadContacts(institutionId: string) {
    try {
      const bodyJson = await TeamDao.dbSearch({
        pageSize: 200,
        page: 1,
        entity: 'contacts',
        q: General.getQ({ rules: [{ field: 'institutionId', value: institutionId, operator: '=' }] }),
        sortBy: 'titleCategory',
        sortDir: 'asc',
      })
      setContactsSearchResponse(bodyJson);
    } catch (err) {
      General.logAndToastError('Failed to load contacts', err)
    } finally {
      setIsLoadingContacts(false)
    }
  }

  async function onExportToCsv(institutionId: string, ids?: string[]) {
    try {
      setIsExportingToCsv(true)
      const data: any = {
        pageSize: PAGE_SIZE,
        page: 1,
        entity: 'contacts',
        q: General.getQ({ rules: [{ field: 'institutionId', value: institutionId, operator: '=' }] }),
        sortBy: 'titleCategory',
        sortDir: 'asc',
        action: APIAction.EXPORT_CSV,
      }
      if (Array.isArray(ids)) data.ids = ids
      const bodyJson = await TeamDao.dbSearch(data)
      await General.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      General.logAndToastError('Something went wrong exporting to a CSV', error)
    } finally {
      setIsExportingToCsv(false)
    }
  }

  useEffect(() => {
    loadInstitution();
  }, [props.id]);

  return (
    <GlobalContext.Consumer>
      {(globalContextProps) => (
        <>
          <div className="content content-1000">
            {
              !response ?
                <LoadingBars style={{ margin: '20px 0' }} numberOfRows={1} /> :
                  response.data._source.access === 'PRIVATE' ?
                    <h1 style={{ margin: 0 }}>{response.data._source.name}</h1> :
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                      {
                        response.data._source.dsName ?
                          <h1 style={{ margin: 0 }}><Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${response?.data?._source?.dsId || ''}`}>{response.data._source.dsName}</Link> <FaArrowRight color='var(--amplify-colors-neutral-80)' size={16} style={{ marginRight: 5, marginLeft: 5 }} /> {response.data._source.name}</h1>
                          : <h1 style={{ margin: 0 }}>{ response.data._source.name }</h1>
                      }
                      <div>
                        <Button
                          onClick={() => {
                            navigate(`/directory/contacts?q=${General.getQ({
                              rules: [{
                                field: 'institutionId',
                                value: uid,
                                operator: '='
                              }]
                            })}`);
                          }}
                          className='tiny'
                          size='small'
                        >
                          Search contacts
                        </Button>
                      </div>
                    </div>
            }
          </div>
          <div className="content content-1000">
          {
            !response ?
              <LoadingBars numberOfRows={5} /> :
              <InstitutionTopCard institution={response.data._source} />
          }
          </div>
          <div className={`content ${isContactsTableExpanded ? 'content-1600' : 'content-1000'}`}>
            <CustomList
              searchResponse={contactsSearchResponse}
              isLoading={isLoadingContacts}
              isExportingToCsv={isExportingToCsv}
              showSubheader={false}
              isAllSortingDisabled={true}
              onSort={() => {}}
              showRowActionButtons={false}
              onExportToCsv={(ids?: string[]) => onExportToCsv(uid as string, ids)}
              isExpanded={isContactsTableExpanded}
              onExpandToggle={() => {
                const newValue = !isContactsTableExpanded
                setIsContactsTableExpanded(newValue)
                LocalStorageService.set(LocalStorageKeys.IS_CONTACTS_LIST_ON_CHILD_PAGE_EXPANDED, newValue)
              }}
              tableFields={globalContext.environment.entities.contact.tableFields}
              showExpandButton={true}
              currentSort={{
                sortBy: '',
                sortDir: 'asc'
              }}
              entity={Entity.CONTACT}
              ref={null}
            />
          </div>
        </>
      )}
    </GlobalContext.Consumer>
  );
}

export default ChildInstitution;