import { GlobalContext, GlobalContextProps } from '../global-context';
import General from '../utils/GeneralUtils';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FeedAnnouncementItem from '../custom-components/FeedItems/FeedAnnouncementItem';
import FeedRescanSuperintendentsItem from '../custom-components/FeedItems/FeedRescanSuperintendentsItem';
import { Button } from '@aws-amplify/ui-react';
import LoadingBars from '../custom-components/LoadingBars';
import { TeamDao } from '../utils/TeamDao';

const allowedTypes: any = {
  ANNOUNCEMENT: {
    humanReadable: 'announcement',
  },
  RESCAN_SUPERINTENDENTS: {
    humanReadable: 'rescan',
  },
}

export function Home(props: any) {

  const globalContext = useContext(GlobalContext);
  General.setTitle(document, 'Feed', globalContext);

  const location = useLocation();

  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [eventsResponse, setEventsResponse] = useState<any>(null);

  async function getEvents() {
    try {
      setIsLoadingEvents(true)
      const data = await TeamDao.listEvents({ page: 1 })
      setEventsResponse(data?.data)
    } catch (err) {
      General.logAndToastError('Something went wrong while fetching events', err)
    } finally {
      setIsLoadingEvents(false)
    }
  }

  useEffect(() => {
    getEvents()
  }, [location.search]);

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-800" style={{ padding: 0 }}>
          <div className="feed">
            <div className='feed-controls'>
              <Button
                size='small'
                className='tiny'
                onClick={event => getEvents()}
              >
                Refresh
              </Button>
            </div>
            {
              isLoadingEvents
                ? <div style={{ padding: 25 }}><LoadingBars numberOfRows={10} /></div>
                : eventsResponse?.events
                    ?.filter((event: any) => allowedTypes[event.type])
                    ?.map((event: any) => <div key={event?.id} className={`post post-type-${event?.type}`}>
                      <div className='post-title'>
                        <div className='post-type'>@{ allowedTypes[event.type]?.humanReadable }</div>
                        <div className='post-time'>{ General.daysAgo(event.createdAt) }</div>
                      </div>
                      { event.type === 'ANNOUNCEMENT' && <FeedAnnouncementItem event={event} /> }
                      { event.type === 'RESCAN_SUPERINTENDENTS' && <FeedRescanSuperintendentsItem event={event} /> }
                    </div>)
            }
          </div>
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
