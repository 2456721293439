import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext, GlobalContextProps } from '../global-context';
import General from '../utils/GeneralUtils';
import 'react-querybuilder/dist/query-builder.css';
import { TeamDao } from '../utils/TeamDao';
import { APIAction, Entity, SortOptions } from '../utils/CustomTypes';
import { LocalStorageKeys, LocalStorageService } from '../utils/LocalStorageService';
import { DomainsSearchParams } from '../utils/DomainsSearchParams';
import CustomList from '../custom-components/CustomList';
import { Button, Flex, Pagination } from '@aws-amplify/ui-react';
import QueryEditor from '../custom-components/QueryEditor';

const PAGE_SIZE = 25

export function Domains(props: any) {

  const globalContext = useContext(GlobalContext);
  General.setTitle(document, 'Domains', globalContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [isExportingToCsv, setIsExportingToCsv] = useState(false);
  const [isLoadingDomains, setIsLoadingDomains] = useState(false);
  const [domainsSearchResponse, setDomainsSearchResponse] = useState<any>(null);
  const [currentSearchParams, setCurrentSearchParams] = useState<DomainsSearchParams>();
  const [newSearchParams, setNewSearchParams] = useState<DomainsSearchParams>();
  const [isDomainsTableExpanded, setIsDomainsTableExpanded] = useState(LocalStorageService.get(LocalStorageKeys.IS_DOMAINS_LIST_ON_DOMAINS_PAGE_EXPANDED) === 'true');
  const domainListRef = useRef();

  async function loadDomains() {
    try {
      setIsLoadingDomains(true)
      const bodyJson = await TeamDao.dbSearch({
        pageSize: PAGE_SIZE,
        entity: 'DOMAIN',
        ...currentSearchParams?.toBody()
      })
      setDomainsSearchResponse(bodyJson)
    } catch (err) {
      General.logAndToastError('Something went wrong loading domains', err)
    } finally {
      setIsLoadingDomains(false)
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = {
      page: General.getQueryParamValueOrNull(queryParams, 'page') || 1,
      sortBy: General.getQueryParamValueOrNull(queryParams, 'sortBy') || 'email',
      sortDir: General.getQueryParamValueOrNull(queryParams, 'sortDir') || 'desc',
      q: General.getQueryParamValueOrNull(queryParams, 'q') || '',
    }
    setCurrentSearchParams(new DomainsSearchParams(params))
    setNewSearchParams(new DomainsSearchParams(params))
  }, [location.search]);

  useEffect(() => {
    if (!currentSearchParams) return
    loadDomains()
  }, [currentSearchParams]);

  function onSort(sortOptions: SortOptions) {
    const sortedSearchParams = new DomainsSearchParams({
      ...newSearchParams?.raw(),
      ...sortOptions
    })
    navigate(`?${sortedSearchParams?.serialize('1')}`);
  }

  function handlePageChange(newPageNumber: number | undefined) {
    if (!newPageNumber) return
    navigate(`?${newSearchParams?.serialize(`${newPageNumber}`)}`);
  }

  function handleOnSearch() {
    if ((domainListRef?.current as any)?.clearSelectedDomains) {
      (domainListRef?.current as any)?.clearSelectedDomains();
    }
    navigate(`?${newSearchParams?.serialize('1')}`);
  }

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1000">
          <div className="main-card">
            <div style={{ padding: 15 }}>
              {
                currentSearchParams
                  ? <QueryEditor
                      initialQuery={currentSearchParams?.q.serialize()}
                      supportedFields={globalContextProps.environment.entities.domains.queryEditorSupportedFields}
                      teamTags={[]}
                      type='standard'
                      onChange={(query: string | null) => {
                        setNewSearchParams(prevState => new DomainsSearchParams({
                          ...prevState?.raw(),
                          ...{ q: query }
                        }))
                      }}
                    />
                  : <></>
              }
              <Flex justifyContent='flex-end' marginTop={30} gap={5}>
                <Button
                  variation='primary'
                  size='small'
                  isDisabled={(currentSearchParams?.serialized === newSearchParams?.serialized) || newSearchParams?.q.value === 'eyJydWxlcyI6W10sImNvbWJpbmF0b3IiOiJhbmQiLCJub3QiOmZhbHNlfQ=='}
                  isLoading={isLoadingDomains}
                  onClick={handleOnSearch}
                  loadingText="Searching"
                >Search</Button>
              </Flex>
            </div>
          </div>
        </div>
        <div className={`content ${isDomainsTableExpanded ? 'content-1600' : 'content-1000'}`}>
          <CustomList
            searchResponse={domainsSearchResponse}
            isLoading={isLoadingDomains}
            isExportingToCsv={isExportingToCsv}
            onSort={onSort}
            onExportToCsv={() => {}}
            showExportButton={false}
            isExpanded={isDomainsTableExpanded}
            onExpandToggle={() => {
              const newValue = !isDomainsTableExpanded
              setIsDomainsTableExpanded(newValue)
              LocalStorageService.set(LocalStorageKeys.IS_DOMAINS_LIST_ON_DOMAINS_PAGE_EXPANDED, newValue)
            }}
            currentSort={{
              sortBy: currentSearchParams?.sortBy?.value,
              sortDir: currentSearchParams?.sortDir?.value
            }}
            showExpandButton={true}
            entity={Entity.DOMAIN}
            tableFields={globalContext.environment.entities.domains.tableFields}
            ref={domainListRef}
          />
          {
            !isLoadingDomains &&
              <Flex
                marginTop={30}
                marginBottom={50}
                justifyContent='center'
              >
                <Pagination
                  currentPage={parseInt(currentSearchParams?.page.value)}
                  totalPages={Math.ceil(domainsSearchResponse?.data?.hits?.total?.value / PAGE_SIZE)}
                  siblingCount={1}
                  onChange={handlePageChange}
                />
              </Flex>
          }
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
