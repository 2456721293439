import { Link } from 'react-router-dom';
import { GlobalContext, GlobalContextProps } from '../global-context';
import General from '../utils/GeneralUtils';
import { Text } from '@aws-amplify/ui-react';
import { useContext } from 'react';

export function NotFound(props: any) {

  const globalContext = useContext(GlobalContext);
  General.setTitle(document, 'Not Found', globalContext);

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1000">
          <h1>Not Found :(</h1>
          <Text>This URL does not exists. Please double check the URL or navigate to the <Link to="/">Homepage</Link>.</Text>
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
