import { Link as AmplifyLink } from "@aws-amplify/ui-react"
import { FaExternalLinkAlt } from "react-icons/fa";

interface PhysicalAddressProps {
  isLink?: boolean;
  row: any;
}

export default function PhysicalAddress({ isLink = false, row }: PhysicalAddressProps) {
  const address = row.locAddressPhys
  return <>
    {
      isLink ?
        <AmplifyLink target='_blank' href={'https://www.google.com/maps/search/' + address}>{ address } <FaExternalLinkAlt size={11} /></AmplifyLink> :
        address
    }
  </>;
}
