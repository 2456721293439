import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBars from '../custom-components/LoadingBars';
import { GlobalContext } from '../global-context';
import General from '../utils/GeneralUtils';
import InstitutionTopCard from '../custom-components/InstitutionTopCard';
import { TeamDao } from '../utils/TeamDao';
import CustomList from '../custom-components/CustomList';
import { APIAction, Entity } from '../utils/CustomTypes';
import { LocalStorageKeys, LocalStorageService } from '../utils/LocalStorageService';
import { Button } from '@aws-amplify/ui-react';

const PAGE_SIZE = 1000

export function ParentInstitution(props: any) {

  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();

  const childInstitution = globalContext.environment.entities.childInstitution;
  const parentInstitution = globalContext.environment.entities.parentInstitution;

  const { id } = useParams()
  const [institutionId] = useState(id);

  const [responseParent, setResponseParent] = useState<any>(null);
  const [contactsSearchResponse, setContactsSearchResponse] = useState<any>(null);
  const [responseChildInstitutions, setResponseChildInstitutions] = useState<any>(null);

  const [isLoadingParent, setIsLoadingParent] = useState(false);
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  const [isExportingContactsToCsv, setIsExportingContactsToCsv] = useState(false);
  const [isExportingChildInstitutionsToCsv, setIsExportingChildInstitutionsToCsv] = useState(false);

  const [isContactsTableExpanded, setIsContactsTableExpanded] = useState(LocalStorageService.get(LocalStorageKeys.IS_CONTACTS_LIST_ON_PARENT_PAGE_EXPANDED) === 'true');
  const [isInstitutionsTableExpanded, setIsInstitutionsTableExpanded] = useState(LocalStorageService.get(LocalStorageKeys.IS_INSTITUTIONS_LIST_ON_PARENTS_PAGE_EXPANDED) === 'true');

  const institutionsListRef = useRef();

  async function loadParentInstitution() {
    try {
      setIsLoadingParent(true)
      const body = { entity: parentInstitution.type, id: institutionId }
      const bodyJson = await TeamDao.dbGet(body)
      setResponseParent(bodyJson);
      General.setTitle(document, bodyJson?.data?._source?.name, globalContext);
    } catch (err) {
      General.logAndToastError('Something went wrong loading institutions', err)
    } finally {
      setIsLoadingParent(false)
    }
  }

  async function loadContacts() {
    try {
      setIsLoadingContacts(true)
      const bodyJson = await TeamDao.dbSearch({
        pageSize: 200,
        page: 1,
        entity: 'contacts',
        q: General.getQ({ rules: [{ field: 'institutionId', value: institutionId, operator: '=' }] }),
        sortBy: 'titleCategory',
        sortDir: 'asc',
      })
      setContactsSearchResponse(bodyJson);
    } catch (error) {
      General.logAndToastError('Something went wrong loading contacts', error)
    } finally {
      setIsLoadingContacts(false)
    }
  }

  async function loadChildInstitutions() {
    try {
      setIsLoadingChildren(true)
      const body = {
        pageSize: PAGE_SIZE,
        page: 1,
        entity: childInstitution.type,
        q: General.getQ({ rules: [{ field: 'idParentInstitution', value: institutionId, operator: '=' }] })
      }
      const bodyJson = await TeamDao.dbSearch(body)
      setResponseChildInstitutions(bodyJson);
    } catch (error) {
      General.logAndToastError('Something went wrong loading child institutions', error)
    } finally {
      setIsLoadingChildren(false)
    }
  }

  async function onExportContactsToCsv(ids?: string[]) {
    try {
      setIsExportingContactsToCsv(true)
      const data: any = {
        pageSize: PAGE_SIZE,
        page: 1,
        entity: 'contacts',
        q: General.getQ({ rules: [{ field: 'institutionId', value: institutionId, operator: '=' }] }),
        sortBy: 'titleCategory',
        sortDir: 'asc',
        action: APIAction.EXPORT_CSV,
      }
      if (Array.isArray(ids)) data.ids = ids
      const bodyJson = await TeamDao.dbSearch(data)
      await General.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      General.logAndToastError('Something went wrong exporting to a CSV', error)
    } finally {
      setIsExportingContactsToCsv(false)
    }
  }

  async function onExportChildInstitutionsToCsv(ids?: string[]) {
    try {
      setIsExportingChildInstitutionsToCsv(true)
      const data: any = {
        pageSize: PAGE_SIZE,
        page: 1,
        entity: childInstitution.type,
        action: APIAction.EXPORT_CSV,
        q: General.getQ({ rules: [{ field: 'idParentInstitution', value: institutionId, operator: '=' }] })
      }
      if (Array.isArray(ids)) data.ids = ids
      const bodyJson = await TeamDao.dbSearch(data)
      await General.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error) {
      General.logAndToastError('Something went wrong exporting child institutions to a CSV', error)
    } finally {
      setIsExportingChildInstitutionsToCsv(false)
    }
  }

  useEffect(() => {
    loadParentInstitution();
    loadChildInstitutions();
    loadContacts();
  }, [props.id]);

  return (
    <GlobalContext.Consumer>
      {(globalContextProps) =>
        <>
          <div className="content content-1000">
            {
              isLoadingParent || !responseParent ?
                <LoadingBars style={{ margin: '20px 0' }} numberOfRows={1} /> :
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <h1 style={{ margin: 0 }}>{responseParent?.data?._source?.name}</h1>
                  <div>
                    <Button
                      onClick={() => {
                        navigate(`/directory/contacts?q=${General.getQ({
                          rules: [{
                            field: 'institutionId',
                            value: institutionId,
                            operator: '='
                          }]
                        })}`);
                      }}
                      className='tiny'
                      size='small'
                    >
                      Search contacts
                    </Button>
                  </div>
                </div>
            }
          </div>
          <div className="content content-1000">
          {
            isLoadingParent || !responseParent ?
              <LoadingBars numberOfRows={5} /> :
              <InstitutionTopCard institution={responseParent.data._source} />
          }
          </div>
          <div className={`content ${isInstitutionsTableExpanded ? 'content-1600' : 'content-1000'}`} style={{ marginBottom: 10 }}>
            <CustomList
              searchResponse={responseChildInstitutions}
              isLoading={isLoadingChildren}
              isExportingToCsv={isExportingChildInstitutionsToCsv}
              isExpanded={isInstitutionsTableExpanded}
              isAllSortingDisabled={true}
              onExpandToggle={() => {
                const newValue = !isInstitutionsTableExpanded
                setIsInstitutionsTableExpanded(newValue)
                LocalStorageService.set(LocalStorageKeys.IS_INSTITUTIONS_LIST_ON_PARENTS_PAGE_EXPANDED, newValue)
              }}
              showExpandButton={true}
              onSort={() => {}}
              currentSort={{
                sortBy: '',
                sortDir: 'asc'
              }}
              tableFields={childInstitution.tableFields}
              onExportToCsv={(ids?: string[]) => onExportChildInstitutionsToCsv(ids)}
              ref={institutionsListRef}
              entity={childInstitution.type}
            />
          </div>
          <div className={`content ${isContactsTableExpanded ? 'content-1600' : 'content-1000'}`}>
            <CustomList
              searchResponse={contactsSearchResponse}
              isLoading={isLoadingContacts}
              isExportingToCsv={isExportingContactsToCsv}
              onSort={() => {}}
              showRowActionButtons={false}
              showSubheader={false}
              isAllSortingDisabled={true}
              onExportToCsv={(ids?: string[]) => onExportContactsToCsv(ids)}
              isExpanded={isContactsTableExpanded}
              onExpandToggle={() => {
                const newValue = !isContactsTableExpanded
                setIsContactsTableExpanded(newValue)
                LocalStorageService.set(LocalStorageKeys.IS_CONTACTS_LIST_ON_PARENT_PAGE_EXPANDED, newValue)
              }}
              currentSort={{
                sortBy: '',
                sortDir: 'asc'
              }}
              tableFields={globalContext.environment.entities.contact.tableFields}
              showExpandButton={true}
              entity={Entity.CONTACT}
              ref={null}
            />
          </div>
        </>
      }
    </GlobalContext.Consumer>
  );
}

export default ParentInstitution;