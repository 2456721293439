import { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { createContext } from 'react';
import { AuthInfo } from './App'
import { CustomEnvironment, getEnvironment } from './environment';

export interface GlobalContextProps {
  withAuthenticatorProps: WithAuthenticatorProps;
  authInfo: AuthInfo | null;
  environment: CustomEnvironment;
  onSetState: ((authInfo: AuthInfo) => void) | null
}

export const GlobalContext = createContext<GlobalContextProps>({
  withAuthenticatorProps: {},
  authInfo: null,
  environment: getEnvironment(),
  onSetState: null
});
