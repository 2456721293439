import { Button, CheckboxField, Flex, Image, Input, Loader, Menu, MenuButton } from '@aws-amplify/ui-react';
import { forwardRef, useEffect, useState } from 'react';
import LoadingBars from './LoadingBars';
import { BiChevronDown } from 'react-icons/bi';
import { BsArrowsCollapseVertical, BsArrowsExpandVertical } from 'react-icons/bs';
import General from '../utils/GeneralUtils';
import { Entity, FieldConfig, SortOptions, UrlVersion } from '../utils/CustomTypes'
import { TableRowDistrict } from './TableRows/TableRowDistrict'
import { TableRowIDN } from './TableRows/TableRowIDN'
import { TableRowSchool } from './TableRows/TableRowSchool'
import { TableRowHospital } from './TableRows/TableRowHospital'
import { TableRowContact, TableRowHeaderContact } from './TableRows/TableRowContact'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import ReactModal from 'react-modal';
import { TeamDao } from '../utils/TeamDao';
import { toast } from 'react-toastify';
import { TableFields } from '../utils/TableFields';
import { TableRowHeader } from '../custom-components/TableRows/TableRowHeader';
import { TableRowDomain } from './TableRows/TableRowDomain';

export enum OnListEventType {
  ON_TAG_CONTACT = 'ON_TAG_CONTACT',
  ON_UNTAG_CONTACT = 'ON_UNTAG_CONTACT',
}

export type OnListEventProps = {
  type: OnListEventType,
  data: any
}

const CustomList = ({
  searchResponse,
  isLoading,
  isExpanded,
  isExportingToCsv,
  isAllSortingDisabled = false,
  onSort,
  onExportToCsv,
  onExpandToggle,
  onEvent,
  showExpandButton = true,
  showExportButton = true,
  showRowActionButtons = true,
  showSubheader = true,
  currentSort,
  tableFields,
  entity,
}: {
  searchResponse: any,
  isLoading: boolean,
  isExpanded: boolean,
  isExportingToCsv: boolean,
  isAllSortingDisabled?: boolean,
  onSort: (sortOptions: SortOptions) => void,
  onExportToCsv: (ids?: any[]) => void,
  onExpandToggle: () => void,
  onEvent?: (event: OnListEventProps) => void,
  showExpandButton?: boolean,
  showExportButton?: boolean,
  showRowActionButtons?: boolean,
  showSubheader?: boolean,
  currentSort: SortOptions,
  tableFields: TableFields,
  entity: Entity
}, ref: any) => {

  const [visibleFields, setVisibleFields] = useState<string[]>(tableFields.getVisibleFields());
  const [selectedRows, setSelectedRows] = useState<{[key: string]: any}>({});
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [selectedContactForOverride, setSelectedContactForOverride] = useState<any|null>(null);
  const [selectedScreenshot, setSelectedScreenshot] = useState<UrlVersion|undefined>(undefined);
  const [isSavingSelectedContactForOverride, setIsSavingSelectedContactForOverride] = useState<boolean>(false);
  const [formOverrideData, setFormOverrideData] = useState<{
    name: string,
    title: string,
    email: string,
    phone: string,
  }>({
    name: '',
    title: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    const checkedItemsCountLocal = General.getTrueKeys(selectedRows);
    setSelectedRowsCount(checkedItemsCountLocal.length);
  }, [selectedRows])

  function handleCheckboxChange(row: any, event: any) {
    const itemId = event.target.id;
    const isChecked = event.target.checked;
    setSelectedRows(prevState => ({
      ...prevState,
      [itemId]: isChecked ? row : null
    }));
  }

  async function toggleAll(event: any) {
    const contactIds: any = {}
    for (const item of searchResponse?.data?.hits?.hits) contactIds[item._id] = item;
    if (event.target.checked) {
      // If checked, add all contacts to selectedContacts
      setSelectedRows(prevState => ({
        ...prevState,
        ...contactIds
      }));
    } else {
      // If unchecked, remove all contacts from selectedContacts
      const updatedContacts = Object.assign({}, selectedRows);
      for (const key in contactIds) {
        delete updatedContacts[key];
      }
      setSelectedRows(prevState => updatedContacts);
    }
  }

  async function onSaveContactOverride() {
    try {
      setIsSavingSelectedContactForOverride(true)

      const bodyJson = await TeamDao.createOverride({
        entity: 'CONTACT',
        id: selectedContactForOverride?._id,
        value: formOverrideData
      })
      const updatedContact = bodyJson?.data

      const updatedContactSearchResponse = searchResponse?.data?.hits?.hits
      if (Array.isArray(updatedContactSearchResponse)) {
        const contact = updatedContactSearchResponse.find((it: any) => it._id === updatedContact.id)
        Object.assign(contact._source, updatedContact?.value || {})
      }

      toast.success('Successfully updated the contact', { autoClose: 1500, isLoading: false })
      setSelectedContactForOverride(null)
    } catch(e) {
      General.logAndToastError('Something went wrong updating the contact', e)
    } finally {
      setIsSavingSelectedContactForOverride(false)
    }
  }

  return <>
    {
      isLoading ?
        <LoadingBars numberOfRows={12} /> :
        !searchResponse?.data?.hits?.hits?.length ?
          <></> :
          <>
            <div className="main-card">
              <div className='results-row results-header' style={{ borderBottom: 'none' }}>
                <div className="results-row-left-side" style={{ padding: '5px 4px' }}>
                  <CheckboxField
                    label=""
                    name="subscribe"
                    value="yes"
                    size="large"
                    onChange={toggleAll}
                  />
                </div>
                <div className="results-row-content">
                  <Flex
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <div className='desc-3'>
                      Found { General.addCommasToNumber(searchResponse?.data?.hits?.total?.value) }{searchResponse?.data?.hits?.total?.value === 10000 ? '+' : ''} {searchResponse?.data?.hits?.total?.value === 1 ? 'match' : 'matches'}
                      {
                        selectedRowsCount > 0 && <span>, { selectedRowsCount } item{ selectedRowsCount === 1 ? '' : 's' } selected</span>
                      }
                    </div>
                    <Flex direction='row' gap={5}>
                      <div>
                        {
                          isExportingToCsv &&
                            <Loader style={{ marginRight: 10, transform: 'translateY(7px)' }} />
                        }
                      </div>
                      {
                        showExpandButton &&
                          <Button
                            size='small'
                            className='tiny'
                            onClick={() => onExpandToggle()}
                          >
                            {
                              isExpanded
                               ? <BsArrowsCollapseVertical style={{ fontSize: 15 }} />
                               : <BsArrowsExpandVertical style={{ fontSize: 15 }} />
                            }
                          </Button>
                      }
                      {
                        <Menu
                          size='small'
                          alignItems='start'
                          trigger={
                            <MenuButton
                              size='small'
                              className='tiny'
                            >
                              <FaRegEye />
                            </MenuButton>
                          }
                        >
                          {
                            tableFields.getOptions().map((fieldOption) => (
                              <Button
                                key={fieldOption.fieldName}
                                isDisabled={!fieldOption.isHideable}
                                className='menu-item'
                                onClick={() => {
                                  tableFields.toggleVisibility(fieldOption.fieldName)
                                  setVisibleFields(tableFields.getVisibleFields())
                                }}
                              >
                                <div style={{ width: 22, fontSize: 14, paddingTop: 3 }}>
                                  { tableFields.isFieldVisible(fieldOption.fieldName) ? <FaRegEye /> : <FaRegEyeSlash /> }
                                </div>
                                  { FieldConfig[fieldOption.fieldName]?.label }
                              </Button>
                            ))
                          }
                        </Menu>
                      }
                      {
                        showExportButton &&
                          <Menu
                            size='small'
                            alignItems='start'
                            trigger={
                              <MenuButton
                                size='small'
                                className='tiny'
                              >
                                Export <BiChevronDown style={{ marginLeft: 5, marginRight: -3, fontSize: 15 }} />
                              </MenuButton>
                            }
                          >
                            <Button
                              key='exportselected'
                              disabled={selectedRowsCount === 0 || isExportingToCsv}
                              className='menu-item'
                              onClick={() => {
                                onExportToCsv(Object.keys(selectedRows))
                              }}
                            >
                              Export Selected to CSV ({ selectedRowsCount })
                            </Button>
                            <Button
                              key='exportall'
                              disabled={isExportingToCsv}
                              className='menu-item'
                              onClick={() => {
                                onExportToCsv()
                              }}
                            >
                              Export All to CSV
                            </Button>
                          </Menu>
                      }
                    </Flex>
                  </Flex>
                </div>
              </div>
              { entity === Entity.CONTACT && <TableRowHeaderContact tableFields={tableFields} currentSort={currentSort} onSort={onSort} showSubheader={showSubheader} isAllSortingDisabled={isAllSortingDisabled} /> }
              { entity === Entity.SCHOOL && <TableRowHeader tableFields={tableFields} currentSort={currentSort} onSort={onSort} showSubheader={showSubheader} isAllSortingDisabled={isAllSortingDisabled} /> }
              { entity === Entity.DISTRICT && <TableRowHeader tableFields={tableFields} currentSort={currentSort} onSort={onSort} showSubheader={showSubheader} isAllSortingDisabled={isAllSortingDisabled} /> }
              { entity === Entity.HOSPITAL && <TableRowHeader tableFields={tableFields} currentSort={currentSort} onSort={onSort} showSubheader={showSubheader} isAllSortingDisabled={isAllSortingDisabled} /> }
              { entity === Entity.IDN && <TableRowHeader tableFields={tableFields} currentSort={currentSort} onSort={onSort} showSubheader={showSubheader} isAllSortingDisabled={isAllSortingDisabled} /> }
              { entity === Entity.DOMAIN && <TableRowHeader tableFields={tableFields} currentSort={currentSort} onSort={onSort} showSubheader={showSubheader} isAllSortingDisabled={isAllSortingDisabled} /> }
              <div className="results-table">
                {
                  searchResponse?.data?.hits?.hits?.map((row: any) => (
                    <div
                      key={row._id}
                      className="results-row results-content"
                    >
                      <div className="results-row-left-side">
                        <div style={{ margin: '2px 4px' }}>
                          <CheckboxField
                            label=""
                            name="subscribe"
                            value="yes"
                            id={row._id}
                            size="large"
                            checked={selectedRows[row._id] || ''}
                            onChange={(event) => {
                              handleCheckboxChange(row, event)
                              event.stopPropagation();
                            }}
                          />
                        </div>
                      </div>
                      {
                        entity === Entity.CONTACT &&
                          <TableRowContact
                            row={row}
                            tableFields={tableFields}
                            showRowActionButtons={showRowActionButtons}
                            onTagContact={(data) => typeof onEvent === 'function' ? onEvent({ type: OnListEventType.ON_TAG_CONTACT, data }) : null }
                            onUntagContact={(data) => typeof onEvent === 'function' ? onEvent({ type: OnListEventType.ON_UNTAG_CONTACT, data }) : null }
                            onEditContact={(data) => {
                              setFormOverrideData({
                                name: data?._source?.name,
                                title: data?._source?.title,
                                email: data?._source?.email,
                                phone: data?._source?.phone,
                              })
                              setSelectedContactForOverride(data)
                            }}
                            onSelectScreenshot={(data) => {
                              setSelectedScreenshot(data)
                            }}
                          />
                      }
                      { entity === Entity.SCHOOL && <TableRowSchool row={row} tableFields={tableFields} /> }
                      { entity === Entity.DISTRICT && <TableRowDistrict row={row} tableFields={tableFields} /> }
                      { entity === Entity.HOSPITAL && <TableRowHospital row={row} tableFields={tableFields} /> }
                      { entity === Entity.IDN && <TableRowIDN row={row} tableFields={tableFields} /> }
                      { entity === Entity.DOMAIN && <TableRowDomain row={row} tableFields={tableFields} /> }
                    </div>
                  ))
                }
              </div>
            </div>
          </>
    }
    <ReactModal
      isOpen={!!selectedScreenshot}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setSelectedScreenshot(undefined)}
      style={General.getSliderDefaults({ width: '900px' })}
      closeTimeoutMS={200}
      parentSelector={General.getModalRoot}
    >
      <div className='screenshot-wrapper'>
        {
          selectedScreenshot?.boxes?.map((box: any) => {
            return <>
              {
                box?.boxes?.map((bb: any) => {
                  return <>
                    <div className="bounding-box" style={General.getBoundingBoxPosition(selectedScreenshot.screenshot_size, bb)}></div>
                  </>
                })
              }
            </>
          })
        }
        <Image src={`https://d3lox7nr5xy0y9.cloudfront.net/url/${selectedScreenshot?.screenshot}`} alt='Screenshot' />
      </div>
    </ReactModal>
    <ReactModal
      isOpen={!!selectedContactForOverride}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setSelectedContactForOverride(null)}
      style={General.getModalDefaults()}
      parentSelector={General.getModalRoot}
    >
      <div style={{ padding: 20 }}>
        <h3 style={{ marginTop: 0 }}>Edit this contact</h3>
        <div style={{ marginBottom: 20 }}>
          <div className='amplify-label' style={{ fontSize: 15 }}>Name</div>
          <Input
            value={formOverrideData.name}
            style={{ marginTop: 5 }}
            onChange={(event) => {
              setFormOverrideData({
                ...formOverrideData,
                name: event.target.value
              })
            }}
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <div className='amplify-label' style={{ fontSize: 15 }}>Title</div>
          <Input
            value={formOverrideData.title}
            style={{ marginTop: 5 }}
            onChange={(event) => {
              setFormOverrideData({
                ...formOverrideData,
                title: event.target.value
              })
            }}
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <div className='amplify-label' style={{ fontSize: 15 }}>Email</div>
          <Input
            value={formOverrideData.email}
            style={{ marginTop: 5 }}
            type='email'
            onChange={(event) => {
              setFormOverrideData({
                ...formOverrideData,
                email: event.target.value
              })
            }}
          />
        </div>
        <div>
          <div className='amplify-label' style={{ fontSize: 15 }}>Phone</div>
          <Input
            value={formOverrideData.phone}
            style={{ marginTop: 5 }}
            onChange={(event) => {
              setFormOverrideData({
                ...formOverrideData,
                phone: event.target.value
              })
            }}
          />
        </div>
        <Button
          variation='primary'
          isFullWidth={true}
          disabled={isSavingSelectedContactForOverride}
          isLoading={isSavingSelectedContactForOverride}
          loadingText='Saving...'
          style={{ marginTop: 20 }}
          onClick={onSaveContactOverride}
        >
          Save
        </Button>
      </div>
    </ReactModal>
  </>
}

export default forwardRef(CustomList);
