import { Link as AmplifyLink } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import General from '../../utils/GeneralUtils';
import { GlobalContext } from '../../global-context';
import { AiOutlineLink } from 'react-icons/ai';
import UniversalTag from '../UniversalTag';
import { TableFields } from '../../utils/TableFields';
import { FieldName } from '../../utils/CustomTypes';

export function TableRowHospital({
  row,
  tableFields
}: {
  row: any,
  tableFields: TableFields
}) {
  const globalContext = useContext(GlobalContext);

  return <>
    <div className='results-row-content'>
      <div className="results-row-main-row">
        <div style={{ flex: 1.8 }}>
          <div>
            <Link to={`/directory/${globalContext.environment.entities.childInstitution.urlSegment}/${row._id}`} className='main-table-link'>{ General.trim(row._source['name'], 50) }</Link>
            <div style={{ marginTop: 5 }}>
              { [row._source['locCity'] || null, General.getStateName(row._source['locState']) || null].filter(it => !!it).join(', ') }
            </div>
          </div>
          {
            row._source?.phone && <div style={{ marginTop: 8 }} className='desc-2'>
              {
                row._source?.phone && <span>
                  Phone { row._source?.phone }
                </span>
              }
            </div>
          }
        </div>
        {
          tableFields.isFieldVisible(FieldName.TYPE_2) &&
            <div style={{ width: '400px' }}>
              <UniversalTag category={row._source['type2']} />
            </div>
        }
        {
          tableFields.isFieldVisible(FieldName.WEBSITE) &&
            <div style={{ width: '50px' }}>
              {
                row._source['website'] ?
                  <AmplifyLink
                    target='_blank'
                    href={row._source['website']}
                    title={row._source['website']}
                    style={{ width: 18, height: 18, display: 'block' }}
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content={ row._source['website'] }
                  >
                    <AiOutlineLink size={18} />
                  </AmplifyLink> :
                  <></>
              }
            </div>
        }
      </div>
      {/* <div>
        {
          expanedRows.fulltext[row._id] ?
            <div style={{ marginTop: 10 }}>
              {
                row._source?.fulltextMatchedUrls.map((it: any) => (
                  <div key={it} style={{ fontSize: 14 }}>
                    <AmplifyLink
                      target='_blank'
                      href={ it }
                    >
                      { General.ellipsisInMiddle(it, 110) }
                    </AmplifyLink>
                  </div>
                ))
              }
            </div> : <></>
        }
      </div> */}
    </div>
  </>
}
