import { SingleSearchParam } from "./SingleSearchParam";

export class InstitutionsSearchParams {

  page: SingleSearchParam;
  sortBy: SingleSearchParam;
  sortDir: SingleSearchParam;
  q: SingleSearchParam;

  serialized: string;
  
  constructor(params: any) {
    this.q = new SingleSearchParam(params.q || '')
    this.page = new SingleSearchParam(params.page || 1)
    this.sortBy = new SingleSearchParam(params.sortBy || 'name')
    this.sortDir = new SingleSearchParam(params.sortDir || 'desc')
    this.serialized= this.serialize()
  }

  serialize(page?: string) {
    const searchParams = new URLSearchParams()
    searchParams.set('q', this.q.serialize());
    searchParams.set('page', page ? page : this.page.serialize());
    searchParams.set('sortBy', this.sortBy.serialize());
    searchParams.set('sortDir', this.sortDir.serialize());
    return searchParams.toString()
  }

  raw() {
    return {
      page: this.page.value,
      sortBy: this.sortBy.value,
      sortDir: this.sortDir.value,
      serialized: this.serialized,
      q: this.q.value,
    }
  }

  toBody() {
    return {
      sortBy: this.sortBy.value,
      sortDir: this.sortDir.value,
      page: this.page.value,
      q: this.q.value,
    }
  }
}