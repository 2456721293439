export class SingleSearchParam {

  value: any;

  constructor(value: any) {
    this.value = value
  }

  isTruthy() {
    if (!this.value) return false
    if (this.value === 'false') return false
    if (this.value === 'null') return false
    return true
  }

  isFalsy() {
    return !this.isTruthy()
  }

  serialize() {
    return `${this.value}`
  }
}