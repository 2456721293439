import { useContext, useEffect, useState } from 'react';
import { GlobalContext, GlobalContextProps } from '../global-context';
import General from '../utils/GeneralUtils';
import LoadingBars from '../custom-components/LoadingBars';
import { Link, NavLink, useParams } from 'react-router-dom';
import { Card, Flex, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import { TeamDao } from '../utils/TeamDao';
import UniversalTag from '../custom-components/UniversalTag';
import { Link as AmplifyLink } from '@aws-amplify/ui-react';
import { FaExternalLinkAlt } from 'react-icons/fa';

export function Domain(props: any) {

  const globalContext = useContext(GlobalContext);
  General.setTitle(document, 'Domain', globalContext);

  const { id } = useParams()
  // const [domainId] = useState(id);
  const [response, setResponse] = useState<any>(null);
  const [isLoadingDomain, setIsLoadingDomain] = useState(false);

  async function loadDomain(id: string) {
    try {
      setIsLoadingDomain(true)
      const bodyJson = await TeamDao.dbGet({ entity: 'DOMAIN', id })
      setResponse(bodyJson);
      General.setTitle(document, bodyJson.data._source.name, globalContext);
    } catch (err) {
      General.logAndToastError('Failed to load domain', err)
    } finally {
      setIsLoadingDomain(false)
    }
  }

  useEffect(() => {
    if (id) {
      loadDomain(id);
    }
  }, [id]);

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-800">
          {
            !response ?
              <LoadingBars style={{ margin: '20px 0' }} numberOfRows={1} /> :
              <Flex alignItems='center' justifyContent='space-between'>
                <h1 style={{ margin: 0 }}>{ response.data._source?.id || '<Domain\'s name not available>' }</h1>
                <UniversalTag category={response.data._source?.engine} />
              </Flex>
          }
          {
            !response ?
              <LoadingBars numberOfRows={3} /> :
              (response?.data?._source?.parentDomains?.length || response?.data?._source?.subDomains?.length) ?
                <>
                  <h5 style={{ marginBottom: 10 }}>Linked Domains</h5>
                  <Card variation='outlined' style={{ marginTop: 10 }}>
                    {
                      [
                        ...response?.data?._source?.parentDomains || [],
                        ...response?.data?._source?.subDomains || []
                      ].map((item: any, index: number) => (
                        <div key={index}>
                          <Flex>
                            <Link key={index} to={`/directory/${globalContext.environment.entities.domains.urlSegment}/${item}`}>{ item }</Link>
                            {/* <UniversalTag category='PARENT' /> */}
                          </Flex>
                        </div>
                      ))
                    }
                  </Card>
                </> :
                <></>
          }
        </div>
        <div className="content content-1000">
          {
            !response ?
              <LoadingBars numberOfRows={3} style={{ marginTop: 20 }} /> :
              response?.data?._source?.institutions?.length ?
                <>
                  <h5 style={{ marginBottom: 10 }}>Institutions ({ response?.data?._source?.institutions?.length })</h5>
                  <Table size='small' style={{ marginTop: 10, backgroundColor: 'white' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '50%' }}>Name</TableCell>
                        <TableCell style={{ width: '50%' }}>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        response?.data?._source?.institutions?.map((institution: any, index: number) => {
                          return <TableRow>
                            <TableCell style={{ width: '50%' }}>
                              <NavLink
                                to={`/directory/${institution?.type === 'DISTRICT' ? globalContext.environment.entities.parentInstitution.urlSegment : globalContext.environment.entities.childInstitution.urlSegment}/${institution?.id}`}
                                style={{ marginRight: 5 }}
                              >
                                { institution?.name }
                              </NavLink>
                            </TableCell>
                            <TableCell style={{ width: '50%' }}><UniversalTag category={institution?.type} /></TableCell>
                          </TableRow>
                      })
                    }
                    </TableBody>
                  </Table>
                </> :
                <></>
          }
        </div>
        <div className="content content-1000">
          {
            !response ?
              <LoadingBars numberOfRows={3} style={{ marginTop: 20 }} /> :
              response?.data?._source?.importantUrls?.length ?
                <>
                  <h5 style={{ marginBottom: 10 }}>Important URLs ({ response?.data?._source?.importantUrls?.length })</h5>
                  <Table size='small' style={{ marginTop: 10, backgroundColor: 'white' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '50%' }}>URL</TableCell>
                        <TableCell style={{ width: '50%' }}>Page Type and Institution</TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      response?.data?._source?.importantUrls?.map((url: any, index: number) => {
                        return <TableRow>
                          <TableCell style={{ width: '50%' }}>
                            <AmplifyLink
                              target='_blank'
                              href={ url?.url?.url }
                            >
                              { General.replaceMiddleWithDots(url?.url?.url, 35, 20) } <FaExternalLinkAlt size={11} />
                            </AmplifyLink>
                          </TableCell>
                          <TableCell style={{ width: '50%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                            {
                              url?.connections?.map((connection: any, index: number) => {
                                return <div key={index}>
                                  <span style={{ marginRight: 5 }}>
                                    <UniversalTag category={connection?.connection?.pageType} />
                                  </span>
                                  {/* <span style={{ marginRight: 5 }}>
                                  {
                                    connection?.connection?.verifiedBy?.startsWith('HUMAN') &&
                                      <UniversalTag category='HUMAN' />
                                  }
                                  </span> */}
                                  <span style={{ marginRight: 5 }}>
                                    of
                                  </span>
                                  {
                                    connection?.institutions?.map((institution: any, index: number) => {
                                      return <span key={index}>
                                        <NavLink
                                          to={`/directory/${institution?.type === 'DISTRICT' ? globalContext.environment.entities.parentInstitution.urlSegment : globalContext.environment.entities.childInstitution.urlSegment}/${institution?.id}`}
                                        >
                                          { institution?.name }
                                        </NavLink> {/* ({ institution?.type === 'DISTRICT' ? 'District' : 'School' }) */}
                                      </span>
                                    })
                                  }
                                </div>
                              })
                            }
                            </div>
                          </TableCell>
                        </TableRow>
                      })
                    }
                  </Table>
                </> :
                <></>
          }
        </div>
        <div className="content content-1000">
          {
            !response ?
              <LoadingBars numberOfRows={3} style={{ marginTop: 20 }} /> :
              response?.data?._source?.linkedDomains?.length ?
                <>
                  <h5 style={{ marginBottom: 10 }}>Linked Domains ({ response?.data?._source?.linkedDomains?.length })</h5>
                  <Table size='small' style={{ marginTop: 10, backgroundColor: 'white' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '50%' }}>Domain</TableCell>
                        <TableCell style={{ width: '50%' }}>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      response?.data?._source?.linkedDomains?.map((domain: any, index: number) => {
                        return <TableRow>
                          <TableCell style={{ width: '50%' }}>
                            <Link to={`/directory/${globalContext.environment.entities.domains.urlSegment}/${domain?.value}`}>{ domain?.value }</Link>
                          </TableCell>
                          <TableCell style={{ width: '50%' }}>
                            <UniversalTag category={domain?.type} />
                          </TableCell>
                        </TableRow>
                      })
                    }
                  </Table>
                </> :
                <></>
          }
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
