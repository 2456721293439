import './SortLabel.scss';
import { FieldConfig, FieldName, SortOptions } from '../utils/CustomTypes'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

export default function SortLabel({
  fieldName,
  currentSort,
  isDisabled = false,
  onSort
}: {
  fieldName: FieldName,
  currentSort: SortOptions,
  isDisabled?: boolean,
  onSort: (sortOptions: SortOptions) => void
}) {
  return <>
    <span
      className={`sort-label ${isDisabled ? 'disabled' : 'enabled'}`}
      onClick={() => {
        if (isDisabled) return;
        onSort({ sortBy: fieldName, sortDir: currentSort.sortDir === 'asc' ? 'desc' : 'asc' })
      }}
    >
      {
        FieldConfig[fieldName].label || '- Unknown -'
      }
      {
        !isDisabled && currentSort.sortBy === fieldName &&
          <>
            { currentSort.sortDir === 'asc' && <FaArrowUp fontSize={9} style={{ marginLeft: 3 }} /> }
            { currentSort.sortDir === 'desc' && <FaArrowDown fontSize={9} style={{ marginLeft: 3 }} /> }
          </>
      }
    </span>
  </>
}
