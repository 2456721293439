import { ColumnVisibilityPrefix, FieldName, FieldOption } from "./CustomTypes";
import { LocalStorageService } from "./LocalStorageService"

export class TableFields {

  private disambiguator: string;
  private options: FieldOption[];
  private optionsMap: { [key: string]: FieldOption } = {}

  constructor(props: {
    disambiguator: string,
    options: FieldOption[]
  }) {
    this.disambiguator = props.disambiguator
    this.options = props.options
    for (const option of props.options) {
      this.optionsMap[option.fieldName] = option
    }
  }

  private getColumnVisibilityLocalStorageKeyName(fieldName: FieldName): string {
    return [ColumnVisibilityPrefix, this.disambiguator, fieldName]
      .filter(it => !!it)
      .join('_')
  }

  isFieldVisible(fieldName: FieldName): boolean {
    const fieldOption = this.optionsMap[fieldName]
    // If it's not even hideable, then it must be always visible
    if (!fieldOption.isHideable) return true
    const key = this.getColumnVisibilityLocalStorageKeyName(fieldName)
    return LocalStorageService.getCustom(key, `${fieldOption.isVisibleByDefault}`) === 'true'
  }

  isFieldSortable(fieldName: FieldName): boolean {
    const fieldOption = this.optionsMap[fieldName]
    return fieldOption.isSortable
  }

  getViewOptionsDropdownOptions() {
    // return Object.values(this.options).map(option => ({ key: option }))
  }

  toggleVisibility(fieldName: FieldName) {
    const fieldOption = this.optionsMap[fieldName]
    if (!fieldOption.isHideable) return
    const key = this.getColumnVisibilityLocalStorageKeyName(fieldName)
    const isVisible = LocalStorageService.getCustom(key, `${fieldOption.isVisibleByDefault}`) === 'true'
    LocalStorageService.setCustom(key, `${!isVisible}`)
  }

  getOptions() {
    return this.options
  }

  getStyle(fieldName: FieldName) {
    return this.optionsMap[fieldName].style || {}
  }

  getVisibleFields() {
    return this.options
      .filter(option => this.isFieldVisible(option.fieldName))
      .map(it => it.fieldName)
  }
}