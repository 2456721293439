import { Link as AmplifyLink, Flex } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import General from '../../utils/GeneralUtils';
import UniversalTag from '../UniversalTag';
import NA from '../NA';
import EmailAddress from '../EmailAddress';
import { useContext, useState } from 'react';
import { BiChevronDown, BiChevronUp, BiSolidCheckCircle, BiSolidPencil } from 'react-icons/bi';
import { GlobalContext } from '../../global-context';
import { TeamDao } from '../../utils/TeamDao';
import { TableFields } from '../../utils/TableFields';
import { FieldName, SortOptions } from '../../utils/CustomTypes';
import SortLabel from '../SortLabel';

export function TableRowHeaderContact({
  isAllSortingDisabled = false,
  showSubheader,
  currentSort,
  tableFields,
  onSort
}: {
  isAllSortingDisabled?: boolean,
  showSubheader: boolean,
  currentSort: SortOptions,
  tableFields: TableFields,
  onSort: (sortOptions: SortOptions) => void
}) {
  if (!showSubheader) return <></>
  return <>
    <div className='results-subheader' style={{ paddingLeft: 55 }}>
      <div style={{ flex: `1 1 400px` }}>
        { tableFields.isFieldVisible(FieldName.NAME) && <span style={{ marginRight: 10 }}><SortLabel fieldName={FieldName.NAME} isDisabled={isAllSortingDisabled ? true : !tableFields.isFieldSortable(FieldName.NAME)} currentSort={currentSort} onSort={onSort} /></span> }
        { tableFields.isFieldVisible(FieldName.TITLE) && <SortLabel fieldName={FieldName.TITLE} isDisabled={isAllSortingDisabled ? true : !tableFields.isFieldSortable(FieldName.TITLE)} currentSort={currentSort} onSort={onSort} /> }
      </div>
      {
        (
          tableFields.isFieldVisible(FieldName.EMAIL) ||
          tableFields.isFieldVisible(FieldName.PHONE)
        ) && <div style={{ flex: `1 1 200px` }}>
          { tableFields.isFieldVisible(FieldName.EMAIL) && <span style={{ marginRight: 10 }}><SortLabel fieldName={FieldName.EMAIL} isDisabled={isAllSortingDisabled ? true : !tableFields.isFieldSortable(FieldName.EMAIL)} currentSort={currentSort} onSort={onSort} /></span> }
          { tableFields.isFieldVisible(FieldName.PHONE) && <span style={{ marginRight: 10 }}><SortLabel fieldName={FieldName.PHONE} isDisabled={isAllSortingDisabled ? true : !tableFields.isFieldSortable(FieldName.PHONE)} currentSort={currentSort} onSort={onSort} /></span> }
        </div>
      }
      <div style={{ width: 30 }}></div>
    </div>
  </>
}

export function TableRowContact({
  row,
  tableFields,
  showRowActionButtons,
  onTagContact,
  onUntagContact,
  onEditContact,
  onSelectScreenshot,
}: {
  row: any,
  tableFields: TableFields,
  showRowActionButtons: boolean,
  onTagContact: (data: any) => void,
  onUntagContact: (data: any) => void,
  onEditContact: (data: any) => void,
  onSelectScreenshot: (data: any) => void,
}) {
  const globalContext = useContext(GlobalContext);
  const FAVORITE_TAG = 'verified'

  const [isFulltextExpanded, setIsFulltextExpanded] = useState(false)
  const [isSourcesExpanded, setIsSourcesExpanded] = useState(false)
  const [isInstitutionsExpanded, setIsInstitutionsExpanded] = useState(false)

  return <>
    <div className='results-row-content'>
      <div className='results-row-main-row' style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ flex: '1 1 400px' }}>
          <div className='results-row-main-line'>
            <Link className='main-table-link' to={`/directory/${globalContext.environment.entities.contact.urlSegment}/${row._id}`}>
              { General.trim(row._source.name, 50) || <NA /> }
            </Link>
            {
              tableFields.isFieldVisible(FieldName.TITLE) &&
                <>
                  <span className='main-table-title' title={row._source.title}>
                    { row._source.title || <NA /> }
                  </span>
                  <UniversalTag category={row._source.titleCategory} />
                </>
            }
          </div>
          {
            tableFields.isFieldVisible(FieldName.BIO) && row._source?.bios?.length > 0 &&
              <div className='desc-9'>{ row._source?.bios[0]?.bio }</div>
          }
          <div style={{ display: 'flex', marginBottom: -8 }}>
            {
              row._source?.fulltextMatchedUrls?.length ?
                <div style={{ marginRight: 10 }}>
                  <span
                    className='tiny-button'
                    onClick={() => {
                      setIsFulltextExpanded(!isFulltextExpanded)
                    }}
                  >
                    { row._source?.fulltextMatchedUrls?.length } URL{ row._source?.fulltextMatchedUrls?.length === 1 ? '' : 's' } matching fulltext search { isFulltextExpanded ? <BiChevronUp /> : <BiChevronDown /> }
                  </span>
                </div> : <></>
            }
            {
              row._source?.foundOnUrls?.length ?
                <div style={{ marginRight: 10 }}>
                  <span
                    className='tiny-button'
                    onClick={() => {
                      setIsSourcesExpanded(!isSourcesExpanded)
                    }}
                  >
                    { row._source?.foundOnUrls?.length } Source{ row._source?.foundOnUrls?.length === 1 ? '' : 's' } { isSourcesExpanded ? <BiChevronUp /> : <BiChevronDown /> }
                  </span>
                </div> : <></>
            }
            {
              row._source?.belongsTo?.length ?
                <div style={{ marginRight: 5 }}>
                  <span
                    className='tiny-button'
                    onClick={() => {
                      setIsInstitutionsExpanded(!isInstitutionsExpanded)
                    }}
                  >
                    { row._source['belongsTo']?.length } { General.getStatesFromBelongsTo(row._source['belongsTo']) } institution{ row._source['belongsTo']?.length === 1 ? '' : 's' } { isInstitutionsExpanded ? <BiChevronUp /> : <BiChevronDown /> }
                  </span>
                </div> : <></>
            }
          </div>
        </div>
        <div style={{ 
          flex: '1 1 200px',
          wordBreak: 'break-all',
          overflowWrap: 'break-word'
        }}>
          {
            tableFields.isFieldVisible(FieldName.EMAIL) &&
              <div style={{ marginBottom: 5 }}>
                <EmailAddress 
                  email={row._source['email']} 
                  emailDomainCount={row._source['emailDomainCount']} 
                  emailVerificationStatus={row._source['emailVerificationStatus']} 
                  emailLastVerifiedAt={row._source['emailLastVerifiedAt']} 
                />
              </div>
          }
          {
            tableFields.isFieldVisible(FieldName.PHONE) &&
              <div>
                { row._source['phone'] }
              </div>
          }
        </div>
      </div>
      <div>
        {
          isFulltextExpanded ?
            <div style={{ marginTop: 15 }}>
              {
                row._source?.fulltextMatchedUrls.map((it: any) => (
                  <div key={it} style={{ fontSize: 14 }}>
                    <AmplifyLink
                      target='_blank'
                      href={ it }
                    >
                      { General.ellipsisInMiddle(it, 110) }
                    </AmplifyLink>
                  </div>
                ))
              }
            </div> : <></>
        }
        {
          isSourcesExpanded ?
            <div style={{ marginTop: 15 }}>
              {
                row._source?.foundOnUrls.map((it: any) => (
                  <div key={it.url} style={{ fontSize: 14, marginTop: 5 }}>
                    <AmplifyLink
                      target='_blank'
                      href={ it?.url }
                    >
                      { General.ellipsisInMiddle(it?.url, 110) }{ it?.page_type?.some((type: any) => type?.startsWith('STAFF_')) ? <span style={{ marginLeft: 5 }} className='tag tag-blue'>Staff Page</span> : <></> }
                    </AmplifyLink>
                    {
                      Array.isArray(it?.versions) ?
                        <Flex gap={5} className="desc-2" alignItems='baseline'>
                          Scanned on
                          {
                            it.versions
                              .sort((a: any, b: any) => (new Date(b?.created_at) as any) - (new Date(a?.created_at) as any))
                              .slice(0, 3)
                              .map((version: any, index: number) => {
                                return <div key={version?.created_at}>
                                  {
                                    new Date(version.created_at).toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' })
                                  }
                                  {
                                    version?.screenshot ?
                                      <span className='clickable' onClick={() => onSelectScreenshot(version)}> (screenshot)</span> :
                                      <></>
                                  }
                                  {
                                    index === it.versions.slice(0, 3).length - 1 ? '' : ','
                                  }
                                </div>
                              })
                          }
                        </Flex> :
                        <></>
                    }
                  </div>
                ))
              }
            </div> : <></>
        }
        {
          isInstitutionsExpanded ?
            <div style={{ marginTop: 15 }}>
              {
                row._source?.belongsTo.map((it: any) => (
                  <div key={it?.id} style={{ marginTop: 8 }}>
                    { ['SCHOOL', 'HOSPITAL'].includes(it?.entityType) && <><UniversalTag category={it?.entityType} /> <Link to={`/directory/${globalContext.environment.entities.childInstitution.urlSegment}/${it?.id || ''}`}>{ it?.name }</Link>, { General.getStateName(it?.state) }</> }
                    { ['DISTRICT', 'IDN'].includes(it?.entityType) && <><UniversalTag category={it?.entityType} /> <Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${it?.id || ''}`}>{ it?.name }</Link>, { General.getStateName(it?.state) }</> }
                  </div>
                ))
              }
            </div> : <></>
        }
      </div>
    </div>
    {
      showRowActionButtons &&
        <div style={{ width: '20px', marginLeft: 10 }}>
          <BiSolidCheckCircle
            title={row?.tags?.includes(FAVORITE_TAG) ? '' : 'Mark as verified'}
            className={`favorite-button ${row?.tags?.includes(FAVORITE_TAG) ? 'is-favorite' : 'is-not-favorite'}`}
            onClick={async () => {
              if (row?.tags?.includes(FAVORITE_TAG)) {
                try {
                  await TeamDao.untag({ resource: 'CONTACT', resourceId: row._id, tag: FAVORITE_TAG })
                  onUntagContact({ row, tag: FAVORITE_TAG })
                } catch (err) {
                  General.logAndToastError('Removing contact from verified failed', err)
                }
              } else {
                try {
                  await TeamDao.tag({ resource: 'CONTACT', resourceId: row._id, tag: FAVORITE_TAG })
                  onTagContact({ row, tag: FAVORITE_TAG })
                } catch (err) {
                  General.logAndToastError('Adding contact to verified failed', err)
                }
              }
            }}
          />
          {/* <BiSolidPencil
            className='edit-button'
            onClick={async () => {
              onEditContact(row)
            }}
          /> */}
        </div>
    }
  </>
}
