import { Button, Flex, Pagination } from '@aws-amplify/ui-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext, GlobalContextProps } from '../global-context';
import { InstitutionsSearchParams } from '../utils/InstitutionsSearchParams';
import General from '../utils/GeneralUtils';
import QueryEditor from '../custom-components/QueryEditor';
import CustomList from '../custom-components/CustomList';
import { TeamDao } from '../utils/TeamDao';
import { APIAction, SortOptions } from '../utils/CustomTypes';
import { LocalStorageService, LocalStorageKeys } from '../utils/LocalStorageService';

const PAGE_SIZE = 25

export function Institutions({
  type
}: {
  type: 'CHILD' | 'PARENT'
}) {

  const globalContext = useContext(GlobalContext);

  const institution = type === 'CHILD' ? globalContext.environment.entities.childInstitution : globalContext.environment.entities.parentInstitution;
  const institutionsListExpansionKey = type === 'CHILD' ? LocalStorageKeys.IS_INSTITUTIONS_LIST_ON_CHILDREN_PAGE_EXPANDED : LocalStorageKeys.IS_INSTITUTIONS_LIST_ON_PARENTS_PAGE_EXPANDED;
  // const institutionsListViewOptionsKey = type === 'CHILD' ? LocalStorageKeys.INSTITUTIONS_LIST_ON_CHILDREN_PAGE_COLUMNS : LocalStorageKeys.INSTITUTIONS_LIST_ON_PARENTS_PAGE_COLUMNS;

  General.setTitle(document, institution.namePlural, globalContext);

  const location = useLocation();
  const navigate = useNavigate();
  
  const institutionsListRef = useRef();

  const [institutionsSearchResponse, setInstitutionsSearchResponse] = useState<any>(null);
  const [isExportingToCsv, setIsExportingToCsv] = useState(false);
  const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(false);
  const [currentSearchParams, setCurrentSearchParams] = useState<InstitutionsSearchParams>();
  const [newSearchParams, setNewSearchParams] = useState<InstitutionsSearchParams>();
  const [isExpanded, setIsExpanded] = useState(LocalStorageService.get(institutionsListExpansionKey) === 'true');
  // const [visibleColumns, setVisibleColumns] = useState<Set<string>>(localStorageService.get(institutionsListViewOptionsKey));

  async function loadInstitutions() {
    try {
      setIsLoadingInstitutions(true)
      const body = { pageSize: PAGE_SIZE, entity: institution.type }
      Object.assign(body, currentSearchParams?.toBody())
      const bodyJson = await TeamDao.dbSearch(body)
      setInstitutionsSearchResponse(bodyJson);
    } catch (err) {
      General.logAndToastError('Something went wrong loading institutions', err)
    } finally {
      setIsLoadingInstitutions(false)
    }
  }

  function handlePageChange(newPageNumber: number | undefined) {
    if (!newPageNumber) return
    navigate(`?${newSearchParams?.serialize(`${newPageNumber}`)}`);
  }

  function handleOnSearch() {
    navigate(`?${newSearchParams?.serialize('1')}`);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = {
      q: General.getQueryParamValueOrNull(queryParams, 'q'),
      sortBy: General.getQueryParamValueOrNull(queryParams, 'sortBy'),
      sortDir: General.getQueryParamValueOrNull(queryParams, 'sortDir'),
      page: General.getQueryParamValueOrNull(queryParams, 'page'),
    }
    setCurrentSearchParams(new InstitutionsSearchParams(params))
    setNewSearchParams(new InstitutionsSearchParams(params))
  }, [location.search]);

  useEffect(() => {
    if (!currentSearchParams) return
    loadInstitutions()
  }, [currentSearchParams]);

  function onSort(sortOptions: SortOptions) {
    const sortedSearchParams = new InstitutionsSearchParams({
      ...newSearchParams?.raw(),
      ...sortOptions
    })
    navigate(`?${sortedSearchParams?.serialize('1')}`);
  }

  async function onExportToCsv(ids: any[] | null = null) {
    try {
      setIsExportingToCsv(true)
      const body: any = { pageSize: PAGE_SIZE, entity: institution.type, action: APIAction.EXPORT_CSV }
      if (Array.isArray(ids)) body.ids = ids
      Object.assign(body, currentSearchParams?.toBody())
      const bodyJson: any = await TeamDao.dbSearch(body)
      await General.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      General.logAndToastError('Something went wrong exporting to a CSV', error)
    } finally {
      setIsExportingToCsv(false)
    }
  }

  return (
    <GlobalContext.Consumer>
      {
        (globalContextProps: GlobalContextProps) => <>
          <div className="content content-1000">
            <div className="main-card">
              <div style={{ padding: 15 }}>
                {
                  currentSearchParams
                    ? <QueryEditor
                        initialQuery={currentSearchParams?.q.serialize()}
                        teamTags={[]}
                        type='standard'
                        supportedFields={institution.queryEditorSupportedFields}
                        onChange={(query: string | null) => {
                          setNewSearchParams(prevState => new InstitutionsSearchParams({
                            ...prevState?.raw(),
                            ...{ q: query }
                          }))
                        }}
                      />
                    : <></>
                }
                <Flex justifyContent='flex-end' marginTop={30} gap={5}>
                  <Button
                    variation='primary'
                    size='small'
                    isDisabled={currentSearchParams?.serialized === newSearchParams?.serialized}
                    isLoading={isLoadingInstitutions}
                    onClick={handleOnSearch}
                    loadingText="Searching"
                  >Search</Button>
                </Flex>
              </div>
            </div>
          </div>

          <div className={`content ${isExpanded ? 'content-1600' : 'content-1000'}`}>
            <CustomList
              searchResponse={institutionsSearchResponse}
              isLoading={isLoadingInstitutions}
              isExpanded={isExpanded}
              onExpandToggle={() => {
                const newValue = !isExpanded
                setIsExpanded(newValue)
                LocalStorageService.set(institutionsListExpansionKey, newValue)
              }}
              isExportingToCsv={isExportingToCsv}
              onSort={onSort}
              currentSort={{
                sortBy: currentSearchParams?.sortBy?.value,
                sortDir: currentSearchParams?.sortDir?.value
              }}
              onExportToCsv={onExportToCsv}
              tableFields={institution.tableFields}
              ref={institutionsListRef}
              entity={institution.type}
            />
            {
              !isLoadingInstitutions &&
                <Flex
                  marginTop={30}
                  marginBottom={50}
                  justifyContent='center'
                >
                  <Pagination
                    currentPage={parseInt(currentSearchParams?.page.value)}
                    totalPages={Math.ceil(institutionsSearchResponse?.data?.hits?.total?.value / PAGE_SIZE)}
                    siblingCount={1}
                    onChange={handlePageChange}
                  />
                </Flex>
            }
          </div>
        </>
      }
    </GlobalContext.Consumer>
  )
}
