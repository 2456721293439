import { Link as AmplifyLink, Image, Text, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import './SideNav.scss';

import { Link, NavLink } from 'react-router-dom';
import { GlobalContext, GlobalContextProps } from '../global-context';
import { DataStore } from 'aws-amplify/datastore';
import { useContext } from 'react';

async function signOut(withAuthenticatorProps: WithAuthenticatorProps) {
  if (withAuthenticatorProps.signOut) {
    console.log(`Starting to sign the use out and clear data store`)
    withAuthenticatorProps.signOut()
    console.log(`User signed out`)
    await DataStore.clear()
    console.log(`Data store cleared`)
  }
}

function getNavLinkClassName(params: any, level: number = 1, className: string = ''): string {
  const { isActive, isPending } = params;
  return isPending
    ? [className, 'link', 'pending', `link-level-${level}`].filter(it => !!it).join(' ')
    : isActive
      ? [className, 'link', 'active', `link-level-${level}`].filter(it => !!it).join(' ')
      : [className, 'link', `link-level-${level}`].filter(it => !!it).join(' ')
}

export function SideNav() {
  const globalContext = useContext(GlobalContext);

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className='side-nav'>
          <div className='top-nav-items'>
            <div className="inner variant-2">
              <Link to='/' className='logo'>
                <Image src={`/${globalContext.environment.logo}`} width={globalContext.environment.logoWidth} alt={globalContext.environment.name} />
              </Link>
            </div>
            <ul>
              {/* <NavLink to='/agent' style={{ position: 'relative' }} className={(params) => getNavLinkClassName(params, 1, 'magic')}>GPT <span className='beta'>new</span></NavLink> */}
              <NavLink to='/home' style={{ position: 'relative' }} className={getNavLinkClassName}>Home</NavLink>
              <span className='side-nav-title'>Directory</span>
              <NavLink
                to={`/directory/contacts${ typeof globalContextProps.authInfo?.team?.defaultContactsDirectoryQueryString === 'string' ? globalContextProps.authInfo?.team?.defaultContactsDirectoryQueryString : globalContext.environment.entities.contact.defaultQueryString }`}
                className={getNavLinkClassName}
              >
                Contacts
              </NavLink>
              <NavLink
                to={`/directory/${ globalContext.environment.entities.childInstitution.urlSegment }${ typeof globalContextProps.authInfo?.team?.defaultSchoolsDirectoryQueryString === 'string' ? globalContextProps.authInfo?.team?.defaultSchoolsDirectoryQueryString : globalContext.environment.entities.childInstitution.defaultQueryString }`}
                className={getNavLinkClassName}
              >
                { globalContext.environment.entities.childInstitution.namePlural }
              </NavLink>
              <NavLink
                to={`/directory/${ globalContext.environment.entities.parentInstitution.urlSegment }${ typeof globalContextProps.authInfo?.team?.defaultDistrictsDirectoryQueryString === 'string' ? globalContextProps.authInfo?.team?.defaultDistrictsDirectoryQueryString : globalContext.environment.entities.parentInstitution.defaultQueryString }`}
                className={getNavLinkClassName}
              >
                { globalContext.environment.entities.parentInstitution.namePlural }
              </NavLink>
              <NavLink
                to={`/directory/${ globalContext.environment.entities.domains.urlSegment }${ typeof globalContextProps.authInfo?.team?.defaultDomainsDirectoryQueryString === 'string' ? globalContextProps.authInfo?.team?.defaultDomainsDirectoryQueryString : globalContext.environment.entities.domains.defaultQueryString }`}
                className={getNavLinkClassName}
                style={{ position: 'relative' }}
              >
                { globalContext.environment.entities.domains.namePlural } <span className='beta'>new</span>
              </NavLink>

              {
                globalContext.environment.flags.shouldEnableServiceAgenciesPage &&
                  <NavLink to='/service-agencies' className={getNavLinkClassName}>Service Agencies</NavLink>
              }

              {
                (globalContext.environment.flags.shouldEnableProspectorPage || globalContext.environment.flags.shouldEnableNewHiresPage) &&
                  <span className='side-nav-title' style={{ marginTop: 10 }}>Tools</span>
              }
              {
                globalContext.environment.flags.shouldEnableProspectorPage &&
                  <NavLink to='/prospector' style={{ position: 'relative' }} className={getNavLinkClassName}>Prospector</NavLink>
              }
              {
                globalContext.environment.flags.shouldEnableNewHiresPage &&
                  <NavLink to='/new-hires' style={{ position: 'relative' }} className={getNavLinkClassName}>New Hires <span className='beta'>beta</span></NavLink>
              }
            </ul>
          </div>
          <div className='bottom-nav-items'>
            <ul>
              <NavLink to='/team' className={getNavLinkClassName}>Team</NavLink>
              <NavLink to='/profile' className={getNavLinkClassName}>Profile</NavLink>
              <NavLink to='/settings' className={getNavLinkClassName}>Settings</NavLink>
            </ul>
            <div className="inner variant-1">
              <div style={{ padding: '20px 20px 10px 20px' }}>
                <Text color='neutral.90' fontStyle='italic'>
                  { globalContextProps.authInfo?.userAttributes?.given_name } { globalContextProps.authInfo?.userAttributes?.family_name }
                </Text>
              </div>
              <div onClick={() => signOut(globalContextProps.withAuthenticatorProps)} className='link'>Sign Out</div>
            </div>
            <div className="inner variant-2">
              <div><AmplifyLink href={globalContext.environment.urlPrivacy} target='_blank'>Privacy Policy</AmplifyLink></div>
              <div style={{ marginBottom: 10 }}><AmplifyLink href={globalContext.environment.urlTerms} target='_blank'>Terms of Service</AmplifyLink></div>
              <div>© 2024 Edulead, Inc.</div>
              <div>All Rights Reserved</div>
              <div style={{ marginTop: 10 }}>Version 1.92.0</div>
            </div>
          </div>
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
