import { Link } from 'react-router-dom';
import { useContext } from 'react';
import General from '../../utils/GeneralUtils';
import { GlobalContext } from '../../global-context';
import { TableFields } from '../../utils/TableFields';
import { FieldName } from '../../utils/CustomTypes';
import UniversalTag from '../UniversalTag';

export function TableRowDomain({
  row,
  tableFields
}: {
  row: any,
  tableFields: TableFields
}) {
  const globalContext = useContext(GlobalContext);

  return <>
    <div className='results-row-content'>
      <div className="results-row-main-row">
        <div style={tableFields.getStyle(FieldName.ID)}>
          <div>
            <Link
              to={`/directory/${globalContext.environment.entities.domains.urlSegment}/${row._id}`} className='main-table-link'
              style={{ marginRight: '0px' }}
            >
              { General.trim(row._source['id'], 50) }
            </Link>
            <UniversalTag category={row._source['engine']} />
          </div>
        </div>
        {
          tableFields.isFieldVisible(FieldName.NR_INSTITUTIONS) &&
            <div style={tableFields.getStyle(FieldName.NR_INSTITUTIONS)}>
              {
                row._source['nrInstitutions'] > 0 ?
                  <span
                    className="email-domain"
                    data-tooltip-id="main-tooltip"
                    data-tooltip-html={row._source['institutions']?.map((institution: any) => General.replaceMiddleWithDots(institution.name, 40, 10)).join('<br />')}
                    data-tooltip-delay-show={50}
                  >
                    { row._source['nrInstitutions'] }
                  </span>
                  : <span className="desc-10">0</span>
              }
            </div>
        }
        {
          tableFields.isFieldVisible(FieldName.NR_IMPORTANT_URLS) &&
            <div style={tableFields.getStyle(FieldName.NR_IMPORTANT_URLS)}>
              {
                row._source['nrImportantUrls'] > 0 ?
                  <span
                    className="email-domain"
                    data-tooltip-id="main-tooltip"
                    data-tooltip-html={
                      row._source['importantUrls']
                        ?.map((url: any) => General.replaceMiddleWithDots(url?.url?.url, 40, 10))
                        ?.join('<br />')
                    }
                    data-tooltip-delay-show={50}
                  >
                    { row._source['nrImportantUrls'] }
                  </span>
                  : <span className="desc-10">0</span>
              }
            </div>
        }
        {
          tableFields.isFieldVisible(FieldName.NR_LINKED_DOMAINS) &&
            <div style={tableFields.getStyle(FieldName.NR_LINKED_DOMAINS)}>
              {
                (row._source['linkedDomains']?.length || 0) > 0 ?
                  <span
                    className="email-domain"
                    data-tooltip-id="main-tooltip"
                    data-tooltip-html={row._source['linkedDomains']
                      ?.map((domain: any) => `${domain.value}`)
                      ?.slice(0, 20)
                      ?.join('<br />')}
                    data-tooltip-delay-show={50}
                  >
                    { row._source['linkedDomains']?.length }
                  </span>
                  : <span className="desc-10">0</span>
              }
            </div>
        }
      </div>
    </div>
  </>
}
