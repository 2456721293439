import { Link } from 'react-router-dom';
import { useContext } from 'react';
import General from '../../utils/GeneralUtils';
import { GlobalContext } from '../../global-context';
import { TableFields } from '../../utils/TableFields';

export function TableRowIDN({
  row,
  tableFields
}: {
  row: any,
  tableFields: TableFields
}) {
  const globalContext = useContext(GlobalContext);

  return <>
    <div className='results-row-content'>
      <div className="results-row-main-row">
        <div style={{ flex: 1.8 }}>
          <div>
            <Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${row._id}`} className='main-table-link'>{ General.trim(row._source['name'], 50) }</Link>
          </div>
        </div>
      </div>
    </div>
  </>
}
