import { Button, Flex, Loader, Pagination, TextField } from '@aws-amplify/ui-react';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GlobalContext, GlobalContextProps } from '../global-context';
import { ContactsSearchParams } from '../utils/ContactsSearchParams';
import General from '../utils/GeneralUtils';
import { TbTrash } from 'react-icons/tb';
import 'react-querybuilder/dist/query-builder.css';
import QueryEditor from '../custom-components/QueryEditor';
import { TeamDao } from '../utils/TeamDao';
import { APIAction, Entity, SortOptions } from '../utils/CustomTypes';
import CustomList, { OnListEventType } from '../custom-components/CustomList';
import { LocalStorageKeys, LocalStorageService } from '../utils/LocalStorageService';

const PAGE_SIZE = 25

export function Contacts(props: any) {

  const globalContext = useContext(GlobalContext);
  General.setTitle(document, 'Contacts', globalContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [isExportingToCsv, setIsExportingToCsv] = useState(false);
  const [isSaveSearchQueryModalOpened, setIsSaveSearchQueryModalOpened] = useState(false);
  const [isSavedSearchQueriesDrawerOpened, setIsSavedSearchQueriesDrawerOpened] = useState(false);
  const [isSavingQuery, setIsSavingQuery] = useState(false);
  const [searchQueryName, setSearchQueryName] = useState<string>();
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [contactsSearchResponse, setContactsSearchResponse] = useState<any>(null);
  const [currentSearchParams, setCurrentSearchParams] = useState<ContactsSearchParams>();
  const [newSearchParams, setNewSearchParams] = useState<ContactsSearchParams>();
  const [searchQueries, setSearchQueries] = useState<any[]>();
  const [teamTags, setTeamTags] = useState<string[]>([]);
  const [isContactsTableExpanded, setIsContactsTableExpanded] = useState(LocalStorageService.get(LocalStorageKeys.IS_CONTACTS_LIST_ON_CONTACTS_PAGE_EXPANDED) === 'true');
  const contactListRef = useRef();

  async function loadContacts() {
    try {
      setIsLoadingContacts(true)
      const bodyJson = await TeamDao.dbSearch({
        pageSize: PAGE_SIZE,
        entity: 'contacts',
        ...currentSearchParams?.toBody()
      })
      setContactsSearchResponse(bodyJson)
    } catch (err) {
      General.logAndToastError('Something went wrong loading contacts', err)
    } finally {
      setIsLoadingContacts(false)
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = {
      page: General.getQueryParamValueOrNull(queryParams, 'page') || 1,
      sortBy: General.getQueryParamValueOrNull(queryParams, 'sortBy') || 'email',
      sortDir: General.getQueryParamValueOrNull(queryParams, 'sortDir') || 'desc',
      q: General.getQueryParamValueOrNull(queryParams, 'q') || '',
    }
    setCurrentSearchParams(new ContactsSearchParams(params))
    setNewSearchParams(new ContactsSearchParams(params))
  }, [location.search]);

  useEffect(() => {
    if (!currentSearchParams) return
    loadContacts()
    // loadContactsLocations()
    getSearchQueries()
    getTeamTags()
  }, [currentSearchParams]);

  async function getSearchQueries() {
    const data = await TeamDao.listQueries({ entity: 'QUERY_CONTACT' })
    setSearchQueries(data?.data?.queries)
  }

  async function getTeamTags() {
    const data = await TeamDao.listTags()
    setTeamTags(data?.data?.tags)
  }

  async function deleteSearchQuery(searchQuery: any) {
    try {
      await TeamDao.deleteQuery({
        entity: 'QUERY_CONTACT',
        id: searchQuery?.id
      });
      setSearchQueries(searchQueries?.filter(it => it.id !== searchQuery.id))
    } catch (err) {
      General.logAndToastError('Deleting search query failed', err)
    }
  }

  function onSort(sortOptions: SortOptions) {
    const sortedSearchParams = new ContactsSearchParams({
      ...newSearchParams?.raw(),
      ...sortOptions
    })
    navigate(`?${sortedSearchParams?.serialize('1')}`);
  }

  async function onExportToCsv(ids?: any[]) {
    try {
      setIsExportingToCsv(true)
      const data: any = {
        entity: 'contacts',
        action: APIAction.EXPORT_CSV,
        ...currentSearchParams?.toBody()
      }
      if (Array.isArray(ids)) data.ids = ids
      const bodyJson = await TeamDao.dbSearch(data)
      await General.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      General.logAndToastError('Something went wrong exporting to a CSV', error)
    } finally {
      setIsExportingToCsv(false)
    }
  }

  async function saveSearchQuery() {
    try {
      setIsSavingQuery(true)
      const bodyJson = await TeamDao.createQuery({
        entity: 'QUERY_CONTACT',
        name: searchQueryName,
        query: newSearchParams?.serialize()
      })
      setSearchQueries([
        bodyJson.data,
        ...(searchQueries || [])
      ])
      setIsSaveSearchQueryModalOpened(false)
      toast.success('Successfully saved the query')
    } catch (e) {
      General.logAndToastError('Something went wrong saving the query', e)
    } finally {
      setIsSavingQuery(false)
    }
  }

  function handlePageChange(newPageNumber: number | undefined) {
    if (!newPageNumber) return
    navigate(`?${newSearchParams?.serialize(`${newPageNumber}`)}`);
  }

  function handleOnSearch() {
    if ((contactListRef?.current as any)?.clearSelectedContacts) {
      (contactListRef?.current as any)?.clearSelectedContacts();
    }
    navigate(`?${newSearchParams?.serialize('1')}`);
  }

  function onSaveTag({ row, tag }: { row: any, tag: string }) {
    if (!Array.isArray(row.tags)) row.tags = []
    row.tags.push(tag)
    setContactsSearchResponse((prevState: any) => ({
      ...contactsSearchResponse
    }))
    if (!teamTags.includes(tag)) {
      setTeamTags((prevState: any) => [...prevState, tag])
    }
  }

  function onDeleteTag({ row, tag }: { row: any, tag: string }) {
    const index = row.tags.findIndex((it: string) => it === tag)
    row.tags.splice(index, 1)
    setContactsSearchResponse((prevState: any) => ({
      ...contactsSearchResponse
    }))
  }

  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      handleOnSearch();
    }
  };

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1000">
          <div className="main-card">
            <Flex className='results-row results-header' justifyContent='flex-end' alignItems='baseline'>
              <Button
                className='tiny silent'
                onClick={() => setIsSavedSearchQueriesDrawerOpened(true)}
              >Show Saved Queries</Button>
            </Flex>

            <div style={{ padding: 15 }} onKeyDown={handleKeyDown}>
              {
                currentSearchParams && teamTags
                  ? <QueryEditor
                      initialQuery={currentSearchParams?.q.serialize()}
                      supportedFields={globalContextProps.environment.entities.contact.queryEditorSupportedFields}
                      teamTags={teamTags}
                      type='contacts'
                      onChange={(query: string | null) => {
                        setNewSearchParams(prevState => new ContactsSearchParams({
                          ...prevState?.raw(),
                          ...{ q: query }
                        }))
                      }}
                    />
                  : <></>
              }
              <Flex justifyContent='flex-end' marginTop={30} gap={5}>
                <Button
                  disabled={searchQueries && searchQueries?.length >= 30}
                  size='small'
                  onClick={() => setIsSaveSearchQueryModalOpened(true)}
                  loadingText="Saving"
                >
                  Save Query</Button>
                <Button
                  variation='primary'
                  size='small'
                  isDisabled={(currentSearchParams?.serialized === newSearchParams?.serialized) || newSearchParams?.q.value === 'eyJydWxlcyI6W10sImNvbWJpbmF0b3IiOiJhbmQiLCJub3QiOmZhbHNlfQ=='}
                  isLoading={isLoadingContacts}
                  onClick={handleOnSearch}
                  loadingText="Searching"
                >Search</Button>
              </Flex>
            </div>
          </div>
        </div>
        <div className={`content ${isContactsTableExpanded ? 'content-1600' : 'content-1000'}`}>
          <CustomList
            searchResponse={contactsSearchResponse}
            isLoading={isLoadingContacts}
            isExportingToCsv={isExportingToCsv}
            onSort={onSort}
            onExportToCsv={onExportToCsv}
            isExpanded={isContactsTableExpanded}
            onExpandToggle={() => {
              const newValue = !isContactsTableExpanded
              setIsContactsTableExpanded(newValue)
              LocalStorageService.set(LocalStorageKeys.IS_CONTACTS_LIST_ON_CONTACTS_PAGE_EXPANDED, newValue)
            }}
            currentSort={{
              sortBy: currentSearchParams?.sortBy?.value,
              sortDir: currentSearchParams?.sortDir?.value
            }}
            showExpandButton={true}
            entity={Entity.CONTACT}
            tableFields={globalContext.environment.entities.contact.tableFields}
            ref={contactListRef}
            onEvent={(event) => {
              if (event.type === OnListEventType.ON_TAG_CONTACT) {
                onSaveTag(event.data)
              }
              if (event.type === OnListEventType.ON_UNTAG_CONTACT) {
                onDeleteTag(event.data)
              }
            }}
          />
          {
            !isLoadingContacts &&
              <Flex
                marginTop={30}
                marginBottom={50}
                justifyContent='center'
              >
                <Pagination
                  currentPage={parseInt(currentSearchParams?.page.value)}
                  totalPages={Math.ceil(contactsSearchResponse?.data?.hits?.total?.value / PAGE_SIZE)}
                  siblingCount={1}
                  onChange={handlePageChange}
                />
              </Flex>
          }
        </div>
        <ReactModal
          isOpen={isSaveSearchQueryModalOpened}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsSaveSearchQueryModalOpened(false)}
          style={General.getModalDefaults()}
          parentSelector={General.getModalRoot}
        >
          <h4 style={{ marginTop: 0 }}>Save Query</h4>
          <TextField
            placeholder=""
            label="Name"
            onChange={event => {
              setSearchQueryName(event?.target?.value)
            }}
          />
          <Flex justifyContent='flex-end' alignItems='center'>
            { isSavingQuery && <Loader style={{ transform: 'translateY(10px)' }} /> }
            <Button
              style={{ marginTop: 20 }}
              variation='primary'
              size='small'
              isDisabled={isSavingQuery}
              onClick={() => {
                saveSearchQuery()
              }}
            >Save</Button>
          </Flex>
        </ReactModal>
        <ReactModal
          isOpen={isSavedSearchQueriesDrawerOpened}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsSavedSearchQueriesDrawerOpened(false)}
          style={General.getSliderDefaults()}
          closeTimeoutMS={200}
          parentSelector={General.getModalRoot}
        >
          <div style={{
            padding: '0 15px'
          }}>
            <h4>Saved Queries</h4>
          </div>
          <Flex direction="column" style={{ gap: 0, marginTop: 20 }}>
            {
              !searchQueries?.length ?
                <div className="nothing-found">No saved queries found</div> :
                searchQueries?.map(it =>
                  <div key={it.id} className="custom-item-1">
                    <div>
                      <Link to={`/directory/contacts?${it.query}`}>{ it.name }</Link>
                    </div>
                    <div>
                      <Button
                        size='small'
                        variation='link'
                        title='Delete'
                        onClick={() => {
                          deleteSearchQuery(it)
                        }}
                      >
                        <TbTrash />
                      </Button>
                    </div>
                  </div>
                )
            }
          </Flex>
        </ReactModal>
      </>
    }
  </GlobalContext.Consumer>
}
