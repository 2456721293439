import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function FeedRescanSuperintendentsItem({ event = null }: { event: any }) {
  
  const [isDetailExpanded, setIsDetailExpanded] = useState(false);
  
  const confirmedContacts = event?.payload?.contacts?.filter((contact: any) => contact?.foundOnUrls?.length)

  return <div>
    <div>Confirmed <span className='post-link' onClick={event => setIsDetailExpanded(!isDetailExpanded)}>{ confirmedContacts?.length } superintendent{ confirmedContacts?.length === 1 ? '' : 's' }</span> { confirmedContacts?.length === 1 ? 'is' : 'are' } still with their district{ confirmedContacts?.length === 1 ? '' : 's' }</div>
    {
      isDetailExpanded && <div className='post-detail'>
        {
          confirmedContacts?.map((contact: any) => <div key={contact?.id}>
            {/* <div>{ contact?.contact?.id }</div> */}
            <div>
              <Link className='silent-link' to={'/directory/contacts/' + contact?.contact?.id}>{ contact?.contact?.name }</Link>, { contact?.contact?.title } @ { contact?.institution?.name }
            </div>
          </div>)
        }
      </div>
    }
  </div>
}
