import { Button, Card, Flex, Grid, Loader, TextField } from '@aws-amplify/ui-react';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext, GlobalContextProps } from '../global-context';
import General from '../utils/GeneralUtils';
import LoadingBars from '../custom-components/LoadingBars';
import { Link, useLocation } from 'react-router-dom';
import NA from '../custom-components/NA';
import ReactModal from 'react-modal';
import { BiCheck } from 'react-icons/bi';
import { TeamDao } from '../utils/TeamDao';

export function TeamPage(props: any) {

  const globalContext = useContext(GlobalContext);
  General.setTitle(document, 'Team', globalContext);

  const location = useLocation();

  const [team, setTeam] = useState<any>();
  const [teamPageResponse, setTeamPageResponse] = useState<any>();
  const [invitedUserEmail, setInvitedUserEmail] = useState<string>();
  const [isInviteMemberPopupOpened, setIsInviteMemberPopupOpened] = useState(false);
  const [isInvitingTeamMember, setIsInvitingTeamMember] = useState(false);
  const [isUpdatingInvitation, setIsUpdatingInvitation] = useState(false);
  const [isTeamMemberSuccessfullyInvited, setIsTeamMemberSuccessfullyInvited] = useState(false);
  
  useEffect(() => {
    if (globalContext?.authInfo?.teamId) {
      getTeam()
      getTeamPage()
    }
  }, [globalContext.authInfo?.teamId, location.search])

  async function getTeam() {
    const team = await TeamDao.getTeam()
    setTeam(team?.data)
  }

  async function getTeamPage() {
    const teamPage = await TeamDao.getTeamPage()
    setTeamPageResponse(teamPage?.data)
  }

  function hasAvailableSeats() {
    if (teamPageResponse?.teamMembers?.length < team?.nrSeats) return true
    return false
  }

  async function sendInvitation() {
    try {
      setIsInvitingTeamMember(true)

      const createInvitationResponseData = await TeamDao.createInvitation({
        invitedEmailAddress: invitedUserEmail
      })

      setTeamPageResponse({
        ...teamPageResponse,
        invitations: [
          ...teamPageResponse.invitations,
          createInvitationResponseData.data,
        ]
      })
      setIsTeamMemberSuccessfullyInvited(true)
    } catch (e: any) {
      General.logAndToastError('Something went wrong sending the invitation', e)
    } finally {
      setIsInvitingTeamMember(false)
    }
  }

  async function onUpdateInvitation(invitation: any, status: string) {
    try {
      setIsUpdatingInvitation(true)
      await TeamDao.updateInvitation({
        pk: invitation.pk,
        sk: invitation.sk,
        status
      });

      const personalInvitations = [
        ...teamPageResponse.personalInvitations
      ]

      const personalInvitationToUpdate = personalInvitations.find(it => it.pk === invitation.pk && it.sk === invitation.sk)
      personalInvitationToUpdate.status = status

      setTeamPageResponse({
        ...teamPageResponse,
        personalInvitations
      })

      if (status === 'ACCEPTED' && globalContext.onSetState) {
        const authInfo = await TeamDao.fetchAuthInfo()
        globalContext.onSetState(authInfo)
      }
    } catch (err: any) {
      General.logAndToastError('Something went wrong updating the invitation', err)
    } finally {
      setIsUpdatingInvitation(false)
    }
  }

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1000">
          <h2>Team</h2>
          <Card variation='outlined' style={{fontSize: 14, marginTop: 20}}>
            {
              team ? <>
                <Grid
                  templateColumns="100px 1fr"
                  templateRows="1fr 1fr"
                  gap={10}
                >
                  <div className='amplify-label'>Name</div>
                  <div>{ team?.name }</div>
                  <div className='amplify-label'>Created At</div>
                  <div>{ team?.createdAt }</div>
                  {/* <div className='amplify-label'>Created By</div>
                  <div>{ team?.createdByUserEmail }</div> */}
                </Grid>
              </> : <LoadingBars numberOfRows={3} />
            }
          </Card>
          <div style={{ marginTop: 30 }}>
            {
              teamPageResponse && <>
                {
                  General.isOwner(globalContext) && !hasAvailableSeats() && <Flex justifyContent='flex-end'>
                    <div style={{ fontSize: 12 }}>
                      Go to <Link to='/settings'>Settings page</Link> to get more seats
                    </div>
                  </Flex>
                }
                <Flex justifyContent='space-between' alignItems='baseline' style={{ marginTop: 10 }}>
                  <h4 style={{ margin: 0 }}>Team Members{ teamPageResponse?.teamMembers?.length > 0 && <> ({ teamPageResponse?.teamMembers?.length })</> }</h4>
                  <div>
                    {
                      General.isOwner(globalContext) && <Button
                        className='tiny'
                        disabled={!hasAvailableSeats()}
                        onClick={() => {
                          setIsInviteMemberPopupOpened(true)
                        }}
                      >Invite a Member</Button>
                    }
                  </div>
                </Flex>
              </>
            }
          </div>
          <Card variation='outlined' style={{fontSize: 14, marginTop: 10}}>
            {
              teamPageResponse ? <>
                <Grid
                  templateColumns="1fr"
                  templateRows="1fr"
                  gap={10}
                >
                  {
                    teamPageResponse?.teamMembers?.length > 0 ? <>
                      {
                        teamPageResponse?.teamMembers.map((teamMember: any) => {
                          return <Flex key={teamMember.Username} gap={5}>
                            <div style={{ flex: 1 }}>{ teamMember?.Attributes?.given_name } { teamMember?.Attributes?.family_name } { teamMember?.Attributes?.profile === 'OWNER' && '(Team Owner)' }</div>
                            <div style={{ flex: 2 }}>{ teamMember?.Attributes?.email } { teamMember?.Attributes?.email_verified === 'true' ? <span style={{ color: 'var(--amplify-colors-green-80)', fontWeight: 'bold' }}>Verified</span> : <>Not verified</> }</div>
                            <div className='amplify-label'>{ teamMember?.UserCreateDate }</div>
                          </Flex>
                        })
                      }
                    </> : <NA text='No team members found' />
                  }
                </Grid>
              </> : <LoadingBars numberOfRows={3} />
            }
          </Card>

          <div style={{ marginTop: 30 }}>
            <h4 style={{  marginTop: 10, marginBottom: 8 }}>Received Invitations{ teamPageResponse?.personalInvitations?.length > 0 && <> ({ teamPageResponse?.personalInvitations?.length })</> }</h4>
            <div className="desc">Invitations you personally received</div>
          </div>
          <Card variation='outlined' style={{fontSize: 14, marginTop: 10}}>
            {
              teamPageResponse ? <>
                <Grid
                  templateColumns="1fr"
                  templateRows="1fr"
                  gap={10}
                >
                  {
                    teamPageResponse?.personalInvitations?.length > 0 ? <>
                      {
                        teamPageResponse?.personalInvitations.map((invitation: any) => {
                          return <Flex key={invitation.sk} gap={5}>
                            <div>
                              { invitation?.status === 'PENDING' && <span className="tag tag-yellow">{ invitation?.status }</span> }
                              { invitation?.status === 'ACCEPTED' && <span className="tag tag-green">{ invitation?.status }</span> }
                              { invitation?.status === 'DECLINED' && <span className="tag tag-red">{ invitation?.status }</span> }
                            </div>
                            <div style={{ flex: 1, marginLeft: 5 }}>
                              { invitation?.invitedByUserName } ({ invitation?.invitedByUserEmail }) is inviting you to join their team
                            </div>
                            {
                              invitation?.status === 'PENDING' ?
                                <div>
                                  { isUpdatingInvitation && <Loader style={{ transform: 'translateY(4px)' }} /> }
                                  <Button
                                    style={{ marginLeft: 10 }}
                                    className='tiny'
                                    disabled={isUpdatingInvitation}
                                    onClick={onUpdateInvitation.bind(null, invitation, 'ACCEPTED')}
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    style={{ marginLeft: 5 }}
                                    className='tiny'
                                    disabled={isUpdatingInvitation}
                                    onClick={onUpdateInvitation.bind(null, invitation, 'DECLINED')}
                                  >
                                    Decline
                                  </Button>
                                </div> :
                                <></>
                            }
                            
                          </Flex>
                        })
                      }
                    </> : <NA text='No received invitations found' />
                  }
                </Grid>
              </> : <LoadingBars numberOfRows={3} />
            }
          </Card>

          <div style={{ marginTop: 30 }}>
            <h4 style={{  marginTop: 10, marginBottom: 8 }}>Sent Invitations{ teamPageResponse?.invitations?.length > 0 && <> ({ teamPageResponse?.invitations?.length })</> }</h4>
            <div className="desc">Invitations your team has sent out</div>
          </div>
          <Card variation='outlined' style={{fontSize: 14, marginTop: 10}}>
            {
              teamPageResponse ? <>
                <Grid
                  templateColumns="1fr"
                  templateRows="1fr"
                  gap={10}
                >
                  {
                    teamPageResponse?.invitations?.length > 0 ? <>
                      {
                        teamPageResponse?.invitations.map((invitation: any) => {
                          return <Flex key={invitation.sk} gap={5}>
                            <div>
                              { invitation?.status === 'PENDING' && <span className="tag tag-yellow">{ invitation?.status }</span> }
                              { invitation?.status === 'ACCEPTED' && <span className="tag tag-green">{ invitation?.status }</span> }
                              { invitation?.status === 'DECLINED' && <span className="tag tag-red">{ invitation?.status }</span> }
                            </div>
                            <div style={{ flex: 1, marginLeft: 5 }}>
                              { invitation?.invitedEmailAddress }
                            </div>
                            <div className='amplify-label'>
                              { invitation?.createdAt }
                            </div>
                          </Flex>
                        })
                      }
                    </> : <NA text='No sent invitations found' />
                  }
                </Grid>
              </> : <LoadingBars numberOfRows={3} />
            }
          </Card>
        </div>


        <ReactModal
          isOpen={isInviteMemberPopupOpened}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsInviteMemberPopupOpened(false)}
          onAfterClose={() => {
            setIsTeamMemberSuccessfullyInvited(false)
            setInvitedUserEmail(undefined)
          }}
          style={General.getModalDefaults()}
          closeTimeoutMS={200}
          parentSelector={General.getModalRoot}
        >
          {
            !isTeamMemberSuccessfullyInvited ?
              <>
                <h4 style={{ marginTop: 0 }}>Invite a Member</h4>
                <TextField
                  descriptiveText="Enter email address of the person you're inviting to join your team"
                  placeholder="some@email.com"
                  label="Email Address"
                  type='email'
                  onChange={event => {
                    setInvitedUserEmail(event?.target?.value)
                  }}
                />
                <Flex justifyContent='flex-end' alignItems='center'>
                  { isInvitingTeamMember && <Loader style={{ transform: 'translateY(10px)' }} /> }
                  <Button
                    style={{ marginTop: 20 }}
                    variation='primary'
                    size='small'
                    isDisabled={isInvitingTeamMember || !invitedUserEmail}
                    onClick={() => {
                      sendInvitation()
                    }}
                  >Send Invitation</Button>
                </Flex>
              </> :
              <>
                <Flex justifyContent='flex-start' alignItems='flex-start' style={{ color: 'var(--amplify-colors-green-80)' }}>
                  <BiCheck size={40} style={{ transform: 'translateY(-9px)' }} />
                  <h4 style={{ marginTop: 0 }}>Invitation successfully created</h4>
                </Flex>
                <p>
                  User '{ invitedUserEmail }' you just invited can accept the invitation by logging in to { globalContext.environment.name } and navigating to "Team" page
                </p>
                <p className='amplify-label' style={{ fontSize: 13, fontStyle: 'italic' }}>
                  If the invited user doesn't have an account yet, they'll have to sign up first at { globalContext.environment.urlApp }
                </p>
                <Flex justifyContent='flex-end' alignItems='center'>
                  <Button
                    style={{ marginTop: 20 }}
                    variation='primary'
                    size='small'
                    onClick={() => {
                      setIsInviteMemberPopupOpened(false)
                    }}
                  >Got It</Button>
                </Flex>
              </>
          }
        </ReactModal>
      </>
    }
  </GlobalContext.Consumer>
}
