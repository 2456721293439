import { SortOptions } from '../../utils/CustomTypes';
import { TableFields } from '../../utils/TableFields';
import SortLabel from '../../custom-components/SortLabel'

export function TableRowHeader({
  isAllSortingDisabled = false,
  showSubheader,
  currentSort,
  tableFields,
  onSort
}: {
  isAllSortingDisabled?: boolean,
  showSubheader: boolean,
  currentSort: SortOptions,
  tableFields: TableFields,
  onSort: (sortOptions: SortOptions) => void
}) {
  if (!showSubheader) return <></>
  return <>
    <div className='results-subheader' style={{ justifyContent: 'flex-end', paddingLeft: 55 }}>
      {
        tableFields.getOptions()
          .map((option) => {
            return <>
              {
                tableFields.isFieldVisible(option.fieldName) &&
                  <div style={option.style || {}}>
                    <SortLabel
                      fieldName={option.fieldName}
                      isDisabled={isAllSortingDisabled ? true : !tableFields.isFieldSortable(option.fieldName)}
                      currentSort={currentSort}
                      onSort={onSort}
                    />
                  </div>
              }
            </>
          })
      }
    </div>
  </>
}