import './UniversalTag.scss';

export default function UniversalTag({ category = 'unknown' }) {
  const categoryMap: any = {
    'SCHOOL': 'School',
    'DISTRICT': 'District',
    'IDN': 'IDN',
    'HOSPITAL': 'Hospital',
    'ACUTE_CARE_HOSPITAL': 'Acute Care Hospitals',
    'ACUTE_CARE_VETERANS': 'Acute Care - Veterans Administration',
    'ACUTE_CARE_DOD': 'Acute Care - Department of Defense',
    'CRITICAL_ACCESS': 'Critical Access Hospitals',
    'CHILDRENS': 'Childrens',
    'PSYCHIATRIC': 'Psychiatric',
    'PUBLIC': 'Public',
    'PRIVATE': 'Private',
    'UNKNOWN': 'Unknown',
    'MANAGER': 'Manager',
    'TEACHER': 'Teacher',
    'OTHER': 'Other Staff',
    'THRILLSHARE': 'Thrillshare',
    'FINALSITE': 'Finalsite',
    'EDLIO': 'Edlio',
    'WIX': 'Wix',
    'PARENT': 'Parent',
    'CHILD': 'Child',
    'STAFF_DIRECTORY': 'Staff Page',
    'STAFF_SUPERINTENDENT': 'Superintendent Page',
    'STAFF_PRINCIPAL': 'Principal Page',
    'WEBSITE': 'Website',
    'BOT': 'Bot',
    'HUMAN': 'Human verified',
    'SUB': 'Subdomain',
    'SIBLING': 'Sibling',
  }
  return <>
    {
      category !== 'unknown' &&
      <span className={`universal-tag universal-tag-${category.toLowerCase()}`}>
        { categoryMap[category] }
      </span>
    }
  </>
}
