import { Flex } from '@aws-amplify/ui-react';
import './ScanUrlProgressIndicator.scss';

export default function ScanUrlProgressIndicator({ url }: { url: any }) {

  const getEligibilitySentence = () => {
    if (url?.status === 'OK') {
      return 'Eligible for scanning';
    } else if (url?.status === 'DUPLICATE') {
      return 'Duplicate URL';
    } else if (url?.status === 'INVALID') {
      return 'Invalid URL';
    } else if (url?.status === 'INELIGIBLE') {
      return 'Ineligible for scanning because the URL was recently scanned';
    } else {
      return url?.status;
    }
  }

  const getEligibilityClass = () => {
    if (url?.status === 'OK') {
      return 'scan-url-progress-indicator-circle-green'
    } else {
      return 'scan-url-progress-indicator-circle-orange'
    }
  }

  const getCrawlerSentence = () => {
    if (url?.crawlerStatus?.startsWith('DONE#SUCCESS')) {
      return 'Crawler finished successfully';
    } else if (url?.crawlerStatus?.startsWith('DONE#ERROR')) {
      return 'Crawler finished with an error';
    } else if (url?.crawlerStatus === 'RUNNING') {
      return 'Crawler is running';
    } else if (url?.crawlerStatus === 'DONE#WONT_START') {
      return 'Crawler won\'t start';
    } else {
      return url?.crawlerStatus
    }
  }

  const getCrawlerClass = () => {
    if (url?.crawlerStatus === 'RUNNING') {
      return 'scan-url-progress-indicator-circle-purple'
    } else if (url?.crawlerStatus?.startsWith('DONE#SUCCESS')) {
      return 'scan-url-progress-indicator-circle-green'
    } else {
      return 'scan-url-progress-indicator-circle-orange'
    }
  }

  const getInferenceSentence = () => {
    if (url?.inferenceStatus?.startsWith('DONE#SUCCESS')) {
      return 'Finding contacts finished successfully';
    } else if (url?.inferenceStatus?.startsWith('DONE#ERROR')) {
      return 'Finding contacts finished with an error';
    } else if (url?.inferenceStatus === 'RUNNING') {
      return 'Finding contacts';
    } else {
      return url?.inferenceStatus
    }
  }

  const getInferenceClass = () => {
    if (url?.inferenceStatus === 'RUNNING') {
      return 'scan-url-progress-indicator-circle-purple'
    } else if (url?.inferenceStatus?.startsWith('DONE#SUCCESS')) {
      return 'scan-url-progress-indicator-circle-green'
    } else {
      return 'scan-url-progress-indicator-circle-orange'
    }
  }

  return <>
    <Flex className='scan-url-progress-indicator' gap={2}>
      <div
        className={`scan-url-progress-indicator-circle ${ getEligibilityClass() }`}
        data-tooltip-id='main-tooltip' data-tooltip-content={ getEligibilitySentence() }
      ></div>
      <div
        className={`scan-url-progress-indicator-circle ${ getCrawlerClass() }`}
        data-tooltip-id='main-tooltip' data-tooltip-content={ getCrawlerSentence() }
      ></div>
      <div
        className={`scan-url-progress-indicator-circle ${ getInferenceClass() }`}
        data-tooltip-id='main-tooltip' data-tooltip-content={ getInferenceSentence() }
      ></div>
    </Flex>
  </>;
}
