import { FieldName, Entity } from "./utils/CustomTypes"
import { TableFields } from "./utils/TableFields"

export type CustomEnvironment = {
  name: string,
  urlApp: string,
  urlWeb: string,
  urlWebName: string,
  urlPrivacy: string,
  urlTerms: string,
  logo: string,
  logoWidth: number,
  entities: {
    contact: {
      type: Entity,
      defaultQueryString: string,
      queryEditorSupportedFields: string[],
      urlSegment: string,
      tableFields: TableFields,
    }
    parentInstitution: {
      type: Entity,
      name: string,
      namePlural: string,
      urlSegment: string,
      defaultQueryString: string,
      tableFields: TableFields,
      queryEditorSupportedFields: string[],
    },
    childInstitution: {
      type: Entity,
      name: string,
      namePlural: string,
      urlSegment: string,
      defaultQueryString: string,
      tableFields: TableFields,
      queryEditorSupportedFields: string[],
    },
    domains: {
      type: Entity,
      name: string,
      namePlural: string,
      urlSegment: string,
      defaultQueryString: string,
      tableFields: TableFields,
      queryEditorSupportedFields: string[],
    },
  },
  flags: {
    shouldEnableServiceAgenciesPage: boolean,
    shouldEnableProspectorPage: boolean,
    shouldEnableNewHiresPage: boolean,
    shouldEnableDomainsPage: boolean,
  },
}

export function getFlavor(): string {
  try {
    return process.env.REACT_APP_FLAVOR || 'EDULEAD'
  } catch {
    return 'EDULEAD'
  }
}

export function getEnvironment(): CustomEnvironment {
  const flavor = getFlavor()
  console.log('Getting environment')
  return environment?.[flavor] || environment['EDULEAD']
}

const environment: { [key: string]: CustomEnvironment } = {
  EDULEAD: {
    name: 'Edulead',
    urlApp: 'https://app.getedulead.com',
    urlWeb: 'https://www.getedulead.com',
    urlWebName: 'getedulead.com',
    urlPrivacy: 'https://getedulead.com/privacy',
    urlTerms: 'https://getedulead.com/terms',
    logo: 'logo-edulead.svg',
    logoWidth: 121,
    entities: {
      contact: {
        type: Entity.CONTACT,
        urlSegment: 'contacts',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJ0aXRsZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlIjoiU3VwZXJpbnRlbmRlbnQsIERpcmVjdG9yIG9mIFRlY2hub2xvZ3ksIENoaWVmIEZpbmFuY2lhbCBPZmZpY2VyIn1dLCJjb21iaW5hdG9yIjoiYW5kIiwibm90IjpmYWxzZX0%3D&page=1&sortBy=email&sortDir=asc',
        tableFields: new TableFields({
          disambiguator: Entity.CONTACT,
          options: [{
            fieldName: FieldName.NAME,
            isVisibleByDefault: true,
            isHideable: false,
            isSortable: true,
            style: { flex: 1.8 },
          }, {
            fieldName: FieldName.TITLE,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
          }, {
            fieldName: FieldName.BIO,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
          }, {
            fieldName: FieldName.EMAIL,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { flex: 1 },
          }, {
            fieldName: FieldName.PHONE,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
          }]
        }),
        queryEditorSupportedFields: [
          'zip',
          'ncesId',
          'districtRevTotal',
          'studentTchrRatio',
          'studentsTotal',
          'stfTotal',
          'countyId',
          'fulltext',
          'grade',
          'tag',
          'name',
          'title',
          'titleCategory',
          'email',
          'emailVerificationStatus',
          'phone',
          'shapes',
          'state',
          'institutionType',
          'religiousAffiliation',
          'parentName',
          'belongsToCountTotal',
          'institutionId',
        ]
      },
      parentInstitution: {
        type: Entity.DISTRICT,
        name: 'District',
        namePlural: 'Districts',
        urlSegment: 'districts',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJzdGF0ZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlU291cmNlIjoidmFsdWUiLCJ2YWx1ZSI6IkNBLFRYLEZMLFBBIn0seyJmaWVsZCI6ImRpc3RyaWN0UmV2VG90YWwiLCJvcGVyYXRvciI6Ij4iLCJ2YWx1ZVNvdXJjZSI6InZhbHVlIiwidmFsdWUiOiI1MDAwMDAwMCJ9XSwiY29tYmluYXRvciI6ImFuZCIsIm5vdCI6ZmFsc2V9&page=1&sortBy=name&sortDir=asc',
        tableFields: new TableFields({
          disambiguator: Entity.DISTRICT,
          options: [{
            fieldName: FieldName.NAME,
            isVisibleByDefault: true,
            isHideable: false,
            isSortable: true,
            style: { flex: 1.8 },
          }, {
            fieldName: FieldName.FSCL_REV_TOTAL,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { width: '150px' },
          }, {
            fieldName: FieldName.STUDENTS_TOTAL,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { width: '120px' },
          }, {
            fieldName: FieldName.TOTAL_SCHOOLS,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { width: '140px' },
          }]
        }),
        queryEditorSupportedFields: [
          'name',
          'state',
          'zip',
          'shapes',
          'phone',
          'fax',
          'districtRevTotal',
          'studentsTotal',
          'totalSchools',
          'fulltext'
        ]
      },
      childInstitution: {
        type: Entity.SCHOOL,
        name: 'School',
        namePlural: 'Schools',
        urlSegment: 'schools',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJzdGF0ZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlU291cmNlIjoidmFsdWUiLCJ2YWx1ZSI6IkNBLFRYLEZMLFBBIn0seyJmaWVsZCI6ImRpc3RyaWN0UmV2VG90YWwiLCJvcGVyYXRvciI6Ij4iLCJ2YWx1ZVNvdXJjZSI6InZhbHVlIiwidmFsdWUiOiI1MDAwMDAwMDAifV0sImNvbWJpbmF0b3IiOiJhbmQiLCJub3QiOmZhbHNlfQ%3D%3D&page=1&sortBy=locState&sortDir=asc',
        tableFields: new TableFields({
          disambiguator: Entity.SCHOOL,
          options: [{
            fieldName: FieldName.NAME,
            isVisibleByDefault: true,
            isHideable: false,
            isSortable: true,
            style: { flex: 1.3 },
          }, {
            fieldName: FieldName.ACCESS,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { width: '80px' },
          }, {
            fieldName: FieldName.GRADE_SPAN,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
            style: { width: '190px' },
          }, {
            fieldName: FieldName.DS_FSCL_REV_TOTAL,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { width: '140px' },
          }]
        }),
        queryEditorSupportedFields: [
          'name',
          'state',
          'zip',
          'shapes',
          'phone',
          'fax',
          'districtRevTotal',
          'schoolReligiousAffiliation',
          'schoolAccess',
          'schoolType',
          'grade',
          'fulltext'
        ]
      },
      domains: {
        type: Entity.DOMAIN,
        name: 'Domain',
        namePlural: 'Domains',
        urlSegment: 'domains',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJpZCIsIm9wZXJhdG9yIjoiY29udGFpbnMiLCJ2YWx1ZVNvdXJjZSI6InZhbHVlIiwidmFsdWUiOiJrMTIifSx7ImZpZWxkIjoibnJJbXBvcnRhbnRVcmxzIiwib3BlcmF0b3IiOiI%2BIiwidmFsdWVTb3VyY2UiOiJ2YWx1ZSIsInZhbHVlIjoiMCJ9LHsiZmllbGQiOiJuckluc3RpdHV0aW9ucyIsIm9wZXJhdG9yIjoiPiIsInZhbHVlU291cmNlIjoidmFsdWUiLCJ2YWx1ZSI6IjAifSx7ImZpZWxkIjoibnJMaW5rZWREb21haW5zIiwib3BlcmF0b3IiOiI%2BIiwidmFsdWVTb3VyY2UiOiJ2YWx1ZSIsInZhbHVlIjoiMCJ9XSwiY29tYmluYXRvciI6ImFuZCIsIm5vdCI6ZmFsc2V9&page=1&sortBy=id&sortDir=asc',
        tableFields: new TableFields({
          disambiguator: Entity.DOMAIN,
          options: [{
            fieldName: FieldName.ID,
            isVisibleByDefault: true,
            isHideable: false,
            isSortable: true,
            style: { flex: 2.8 },
          }, {
            fieldName: FieldName.NR_INSTITUTIONS,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { flex: 1 },
          }, {
            fieldName: FieldName.NR_IMPORTANT_URLS,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { flex: 1 },
          }, {
            fieldName: FieldName.NR_LINKED_DOMAINS,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { flex: 1 },
          }]
        }),
        queryEditorSupportedFields: [
          'id',
          'nrInstitutions',
          'nrImportantUrls',
          'nrLinkedDomains',
        ]
      }
    },
    flags: {
      shouldEnableServiceAgenciesPage: true,
      shouldEnableProspectorPage: true,
      shouldEnableNewHiresPage: true,
      shouldEnableDomainsPage: true,
    },
  },
  HEALTH: {
    name: 'ClearNode',
    urlApp: 'https://app.clearnode.io',
    urlWeb: 'https://www.clearnode.io',
    urlWebName: 'clearnode.io',
    urlPrivacy: 'https://clearnode.io/privacy',
    urlTerms: 'https://clearnode.io/terms',
    logo: 'logo-clearnode.svg',
    logoWidth: 142,
    entities: {
      contact: {
        type: Entity.CONTACT,
        urlSegment: 'contacts',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJ0aXRsZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlIjoiQ2hpZWYgRmluYW5jaWFsIE9mZmljZXIsIFByZXNpZGVudCJ9XSwiY29tYmluYXRvciI6ImFuZCIsIm5vdCI6ZmFsc2V9&page=1&sortBy=email&sortDir=asc',
        tableFields: new TableFields({
          disambiguator: Entity.CONTACT,
          options: [{
            fieldName: FieldName.NAME,
            isVisibleByDefault: true,
            isHideable: false,
            isSortable: true,
            style: { flex: 1.8 },
          }, {
            fieldName: FieldName.TITLE,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
          }, {
            fieldName: FieldName.BIO,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
          }, {
            fieldName: FieldName.EMAIL,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: true,
            style: { flex: 1 },
          }, {
            fieldName: FieldName.PHONE,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
          }]
        }),
        queryEditorSupportedFields: [
          'zip',
          'countyId',
          'fulltext',
          'name',
          'title',
          'titleCategory',
          'email',
          'emailVerificationStatus',
          'phone',
          'shapes',
          'state',
          'institutionType'
        ]
      },
      parentInstitution: {
        type: Entity.IDN,
        name: 'IDN',
        namePlural: 'IDNs',
        urlSegment: 'idns',
        defaultQueryString: '?q=eyJydWxlcyI6W10sImNvbWJpbmF0b3IiOiJhbmQiLCJub3QiOmZhbHNlfQ==&page=1&sortBy=name&sortDir=asc',
        tableFields: new TableFields({
          disambiguator: Entity.IDN,
          options: [{
            fieldName: FieldName.NAME,
            isVisibleByDefault: true,
            isHideable: false,
            isSortable: true,
            style: { flex: 1 }
          }]
        }),
        queryEditorSupportedFields: [
          'name'
        ]
      },
      childInstitution: {
        type: Entity.HOSPITAL,
        name: 'Hospital',
        namePlural: 'Hospitals',
        urlSegment: 'hospitals',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJzdGF0ZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlU291cmNlIjoidmFsdWUiLCJ2YWx1ZSI6Ik5DIn1dLCJjb21iaW5hdG9yIjoiYW5kIiwibm90IjpmYWxzZX0%3D&page=1&sortBy=name&sortDir=asc',
        tableFields: new TableFields({
          disambiguator: Entity.HOSPITAL,
          options: [{
            fieldName: FieldName.NAME,
            isVisibleByDefault: true,
            isHideable: false,
            isSortable: true,
            style: { flex: 1.8 },
          }, {
            fieldName: FieldName.TYPE_2,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
            style: { width: '400px' },
          }, {
            fieldName: FieldName.WEBSITE,
            isVisibleByDefault: true,
            isHideable: true,
            isSortable: false,
            style: { width: '50px' },
          }]
        }),
        queryEditorSupportedFields: [
          'name',
          'state'
        ]
      },
      domains: {
        type: Entity.DOMAIN,
        name: 'Domain',
        namePlural: 'Domains',
        urlSegment: 'domains',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJpZCIsIm9wZXJhdG9yIjoiY29udGFpbnMiLCJ2YWx1ZVNvdXJjZSI6InZhbHVlIiwidmFsdWUiOiJrMTIifV0sImNvbWJpbmF0b3IiOiJhbmQiLCJub3QiOmZhbHNlfQ==&page=1&sortBy=id&sortDir=asc',
        tableFields: new TableFields({
          disambiguator: Entity.DOMAIN,
          options: [{
            fieldName: FieldName.ID,
            isVisibleByDefault: true,
            isHideable: false,
            isSortable: true,
            style: { flex: 1.3 },
          }]
        }),
        queryEditorSupportedFields: [
          'id'
        ]
      }
    },
    flags: {
      shouldEnableServiceAgenciesPage: false,
      shouldEnableProspectorPage: false,
      shouldEnableNewHiresPage: false,
      shouldEnableDomainsPage: true,
    },
  }
}
