import './LoadingBars.scss';

export default function LoadingBars({ numberOfRows = 5, style = {} }) {
  return <>
    <div className="loading-bars" style={style}>
      {
        new Array(numberOfRows).fill(null).map((_, i) =>
          <div key={i} className="loading-bar"></div>
        )
      }
    </div>
  </>
}
