import { CSSProperties } from "react"

export type SortDirection = 'asc' | 'desc'

export type SortOptions = {
  sortBy: string,
  sortDir: SortDirection,
}

export enum Entity {
  CONTACT = 'CONTACT',
  SCHOOL = 'SCHOOL',
  DISTRICT = 'DISTRICT',
  HOSPITAL = 'HOSPITAL',
  IDN = 'IDN',
  DOMAIN = 'DOMAIN',
}

export enum APIAction {
  EXPORT_CSV = 'export-csv'
}

export enum FieldName {
  ID = 'id',
  NAME = 'name',
  FSCL_REV_TOTAL = 'fsclRevTotal',
  STUDENTS_TOTAL = 'studentsTotal',
  TOTAL_SCHOOLS = 'totalSchools',
  WEBSITE = 'website',
  ACCESS = 'access',
  GRADE_SPAN = 'gradeSpan',
  DS_FSCL_REV_TOTAL = 'dsFsclRevTotal',
  TITLE = 'title',
  BIO = 'bio',
  EMAIL = 'email',
  PHONE = 'phone',
  TYPE_2 = 'type2',
  NR_INSTITUTIONS = 'nrInstitutions',
  NR_IMPORTANT_URLS = 'nrImportantUrls',
  NR_LINKED_DOMAINS = 'nrLinkedDomains',
}

export type FieldProps = {
  label: string
}

export const FieldConfig: { [key: string]: FieldProps } = {
  [FieldName.NAME]: {
    label: 'Name',
  },
  [FieldName.FSCL_REV_TOTAL]: {
    label: 'District Revenue',
  },
  [FieldName.STUDENTS_TOTAL]: {
    label: 'Nr. of Students',
  },
  [FieldName.TOTAL_SCHOOLS]: {
    label: 'Nr. of Schools',
  },
  [FieldName.WEBSITE]: {
    label: 'Website',
  },
  [FieldName.ACCESS]: {
    label: 'Access',
  },
  [FieldName.GRADE_SPAN]: {
    label: 'Grade Span',
  },
  [FieldName.DS_FSCL_REV_TOTAL]: {
    label: 'District Revenue',
  },
  [FieldName.TITLE]: {
    label: 'Title',
  },
  [FieldName.BIO]: {
    label: 'Bio',
  },
  [FieldName.EMAIL]: {
    label: 'Email',
  },
  [FieldName.PHONE]: {
    label: 'Phone',
  },
  [FieldName.TYPE_2]: {
    label: 'Type',
  },
  [FieldName.ID]: {
    label: 'Domain',
  },
  [FieldName.NR_INSTITUTIONS]: {
    label: 'Nr. of Institutions',
  },
  [FieldName.NR_IMPORTANT_URLS]: {
    label: 'Nr. of Important URLs',
  },
  [FieldName.NR_LINKED_DOMAINS]: {
    label: 'Nr. of Linked Domains',
  },
}

export type FieldOption = {
  fieldName: FieldName
  isVisibleByDefault: boolean
  isHideable: boolean,
  isSortable: boolean,
  style?: CSSProperties
}

export const ColumnVisibilityPrefix = 'IS_COLUMN_VISIBLE'

export interface Box {
  x: number
  y: number
  width: number
  height: number
}

export interface BoundingBox {
  meta: {
    value: string
    type: string
  }
  boxes: Box[]
}

export interface ScreenshotSize {
  width: number
  height: number
}

export interface UrlVersion {
  screenshot: string
  screenshot_size: ScreenshotSize
  boxes: BoundingBox[]
}